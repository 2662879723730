import React, { useContext, useState } from "react";
import "./Navbar.css";
import { CategoryList, apilink, path } from "../../data/fdata";
import { NavLink } from "react-router-dom";
import { DataContext } from "../../context/DataContext";
import { useHistory , useLocation} from "react-router-dom/cjs/react-router-dom.min";
import { useEffect } from "react";
import axios from "axios";
import parse from 'html-react-parser';
import Cookies from "js-cookie";


const Navbar = () => {
  const token = Cookies.get('_indiayour_client_access_token');
  const [searchResult, setSearchResult] = useState([]);
  const [logoutShow, setLogoutShow] = useState(false);
  const [loginStatus,setLoginStatus]=useState(false)
  const [categoryList,setCategoryList]=useState([])
  const [dataList,setDataList]=useState([])
  const [search,setSearch]=useState("")

  const { setShowPop ,location, cart , removeSpecialCharacters ,  contactData} = useContext(DataContext);
  const his = useHistory();
  const loc = useLocation();

  const [place, setPlace] = useState('');


  
  // console.log(position);
   

    
   useEffect(()=>{
    if(location)
    {
        setPlace(location);
   
    }
    
  } ,[location])

  const handleClick = (e) => {
    e.preventDefault();
  };

  const logout=()=>{
    Cookies.remove('_indiayour_client_access_token');
    localStorage.removeItem('_indiayour_client_access_login');
    console.clear();
    window.location.href = '/';
  }

  const onSearchSubmit = (e)=>{
    e.preventDefault()
    his.push(`/search?query=${removeSpecialCharacters(search).trim().toLowerCase().replace(/\s/g, "+")}`)

  }

  const handelSearch=(e)=>{
    e.preventDefault()
    setSearch(e.target.value)


    let arr = dataList.filter(
      (v) =>
        v.pro_title.toLowerCase().includes(e.target.value.toLowerCase()) ||
        v.pro_description.toLowerCase().includes(e.target.value.toLowerCase()) ||
        v.pro_category.toLowerCase().includes(e.target.value.toLowerCase()) ||
        v.pro_subcategory.toLowerCase().includes(e.target.value.toLowerCase()) 
    );

    if(e.target.value.length>0)
    {
      setSearchResult(arr)

    }else{
      setSearchResult([])
    }
  }

  const getallCategory = async()=>{
    const res=await axios.get(`${apilink}/api/category/getallCategory`)
    if(res.data.success)
    {
      setCategoryList(res.data.result)
    }
  }
  const getAllProducts=async()=>{
    
    const res=await axios.get(`${apilink}/api/product/getAllProducts`)
    if(res.data.success){
      setDataList(res.data.result)
    }
    
  }

  useEffect(()=>{
  getallCategory()
  getAllProducts()

  },[])




  useEffect(() => {
   setSearch("")
   setSearchResult([])
  }, [loc]);

  useEffect(async() => {
    if(token) {
     
        const res=await axios.get(`${apilink}/api/user/authVerify`,{
            headers: {
              Authorization: token,
            },
          })
          if(!res.data.success)
          {
            Cookies.remove('_indiayour_client_access_token');
           localStorage.removeItem('_indiayour_client_access_login');
          console.clear();
          window.location.href = '/login';
          }else{
            setLoginStatus(true)
            
  
  
  
          }
          
         
    }else{
      setLoginStatus(false)
    }
  }, []);
  return (
    <>
      <header>
        <div className="header-content-top">
          <div className="content">
            <NavLink to="/" className="top_nav_up">
              <span onClick={() => {
                setShowPop(true)
                document
                .querySelector("#root")
                .classList.add("disable-scroll");
              }}>
                {" "}
                <i className="fa fa-map-marker mr-2 "></i>{place ? place : "Location"}
              </span>
            </NavLink>
            <div className="d-none d-sm-block">
              <a href={`tel:${contactData.phone}`} className="top_nav_up">
                {" "}
                <span>
                  <i className="fas fa-phone-square-alt"></i> { contactData.phone}
                </span>
              </a>

              <a href={`mailto:${ contactData.email}`} className="top_nav_up">
                {" "}
                <span>
                  <i className="fas fa-envelope-square"></i>{ contactData.email}
                </span>
              </a>
            </div>
          </div>
        </div>

        <div className="wholecontainer">
          <NavLink to="/" className="logo">
            <img src={`${path}/image/Indiayour.png`} alt="" draggable="false" />
          </NavLink>

          <label className="open-search" for="open-search">
            <i className="fas fa-search"></i>
            <input
              className="input-open-search"
              id="open-search"
              type="checkbox"
              name="menu"
            />
            <form className="search search_big_div" onSubmit={onSearchSubmit}>
              <button className="button-search">
                <i className="fas fa-search"></i>
              </button>
              <input
                type="text"
                placeholder="What are you looking for?"
                className="input-search"
                required
                value={search}
                onChange={handelSearch}
              />
              {searchResult.length != 0 && (
                <div className="search_div">
                  {searchResult.map((v,ind) => {
                    return (
                      <>
                        <NavLink target="_blank" to={`/product/${removeSpecialCharacters(v.pro_title)?.trim().toLowerCase().replace(/\s/g, "-")}/${v._id}/${location}`} className="search_result_item" onClick={()=>{
                          setSearchResult([])
                          setSearch("")
                        }} key={ind}>
                          

                            {
                                 v.pro_images?.length && <img src={v.pro_images[0]} alt={v?.pro_metadetails?.img_alt.length > 0 ? v?.pro_metadetails?.img_alt : v?.pro_title }  />
                              }
                          <div className="search_result_item_inn">
                            <p className="m-0">{v.pro_title}</p>
                            <small className="top_p1">
                            {parse(v.pro_description)}
                            </small>
                          </div>
                        </NavLink>
                      </>
                    );
                  })}
                </div>
              )}
            </form>
          </label>

          <nav className="nav-content">
            <ul className="nav-content-list">
              <li className="nav-content-item account-login">
                <label
                  className="open-menu-login-account"
                  for="open-menu-login-account"
                >
                  <input
                    className="input-menu"
                    id="open-menu-login-account"
                    type="checkbox"
                    name="menu"
                  />

                  <i className="fas fa-user-circle nav-content-link"></i>

                  <ul className="login-list">
                    {
                      !loginStatus && <li
                      className="login-list-item"
                      onClick={() => his.push("/register")}
                    >
                      <i className="fa fa-user-plus mr-2"></i>Create Account
                    </li>
                    }
                    

                    <li
                      className="login-list-item"
                      onClick={() => his.push("/account")}
                    >
                      {" "}
                      <i className="fa fa-user-o mr-2"></i> My Account
                    </li>
                    <li
                      className="login-list-item"
                      onClick={() => his.push("/orders")}
                    >
                      <i className="fa fa-truck mr-2"></i>Orders
                    </li>

                    <li
                      className="login-list-item"
                      onClick={() => his.push("/wishlist")}
                    >
                      <i className="fa fa-heart-o mr-2"></i>Wishlists
                    </li>

                   {
                    loginStatus &&  <li className="login-list-item login-list-item_logout" onClick={()=>setLogoutShow(true)}>
                    <i className="fa fa-sign-out mr-2"></i>logout
                  </li>
                   }
                  </ul>
                </label>
              </li>
              <li className="nav-content-item">
                <NavLink to="/wishlist" className="nav-content-link">
                  <i className="fas fa-heart"></i>
                </NavLink>
              </li>
              <li className="nav-content-item">
                <NavLink to="/cart" className="nav-content-link nav_icon_bar">
                  <i className="fas fa-shopping-cart"></i>
                  <div>{cart.Totalitems ? cart.Totalitems : 0}</div>
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>

        <div className="nav-container">
          <nav className="all-category-nav">
            <label className="open-menu-all" for="open-menu-all">
              <input
                className="input-menu-all"
                id="open-menu-all"
                type="checkbox"
                name="menu-open"
              />
              <span className="all-navigator">
                <i className="fas fa-bars"></i>{" "}
                {/* onClick={() => {
                  his.push("/all-categories")
                  
                  // document.getElementById("open-menu-all").checked = false;
                  }} */}
                <span >
                  <p className="m-0">All category</p>
                </span>{" "}
                <i className="fas fa-angle-down"></i>
                <i className="fas fa-angle-up"></i>
              </span>

              <ul className="all-category-list">
                {categoryList.map((v,index) => {
                  return (
                    <>
                      <li className="all-category-list-item" key={index}>
                        <p
                          onClick={() => his.push(`/category/${removeSpecialCharacters(v.cat_name).trim().toLowerCase().replace(/\s/g, "-")}/${v._id}`)}
                          className="m-0 all-category-list-link"
                        >
                          {v.cat_name}
                          <i className="fas fa-angle-right"></i>
                        </p>
                        <div className="category-second-list">
                          <ul className="category-second-list-ul">
                            {v.sub_category?.map((val, ind) => {
                              return (
                                <>
                                  <li className="category-second-item" key={ind}>
                                    <p
                                      onClick={() =>
                                        his.push(
                                          `/category/${removeSpecialCharacters(v.cat_name).trim().toLowerCase().replace(/\s/g, "-")}/${v._id}?query=${removeSpecialCharacters(val.subcat_name).trim().toLowerCase().replace(/\s/g, "-")}&sid=${val._id}`
                                        )
                                      }
                                      className="m-0"
                                    >
                                      {val.subcat_name}
                                    </p>
                                  </li>
                                </>
                              );
                            })}
                          </ul>

                          {/* <div className="img-product-menu"><img src="https://i.ibb.co/Vvndkmy/banner.jpg" /></div> */}
                        </div>
                      </li>
                    </>
                  );
                })}
                <li className="all-category-list-item">
                  <NavLink
                    to="/all-categories"
                    className="all-category-list-link text-cat"
                  >
                    View All Category
                  </NavLink>{" "}
                </li>
              </ul>
            </label>
          </nav>
          <nav className="featured-category">
            <ul className="nav-row">
              {categoryList.slice(0,5).map((v,ind) => (
                <li className="nav-row-list" key={ind}>
                  <NavLink to={`/category/${removeSpecialCharacters(v.cat_name).trim().toLowerCase().replace(/\s/g, "-")}/${v._id}`} className="nav-row-list-link">
                    {v.cat_name}
                  </NavLink>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </header>
{
  logoutShow && <div className="model_box">
  <div className="inner_model_sm">
    <div className="cross" onClick={()=>setLogoutShow(false)}>&times;</div>
   
   <div className="text-center">
   <h5>Are you Sure About Logout?</h5>
   <button className="btn btn-primary mt-2" onClick={logout}>Logout</button>
   </div>
  </div>
</div>
}
      
    </>
  );
};

export default Navbar;
