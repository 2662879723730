import React from "react";
import "./Cart.css";
import { NavLink, useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { apilink, stateList } from "../../data/fdata";
import axios from "axios";
import { useState } from "react";
import { useContext } from "react";
import { DataContext } from "../../context/DataContext";
import { CircularProgress } from '@mui/material'


const SubmitRequest = () => {
  const token = Cookies.get("_indiayour_client_access_token");
  const { cart, setCart, alert , gstPriceFunc , discountPriceFunc } = useContext(DataContext);

  const [loading, setLoading] = useState(false);
  const [preload, setPreload] = useState(false);

  const [addressview, setAddressview] = useState(false);
  const [addresslist, setAddresslist] = useState([]);
  const [curaddress, setCurAddress] = useState({});


  const [districtList, setDistrictList] = useState([]);
  const [sdistrictList, setSDistrictList] = useState([]);

  const [totalamount, setTotalAmount] = useState(0);
  const [amountwith_product, setAmountwithProduct] = useState(0);
  const [amountwith_tax, setAmountwithTax] = useState(0);
  const [amountwith_shipping, setAmountwithShipping] = useState(0);




  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [customer, setCustomer] = useState("");

  const [gstin, setGstin] = useState("");
  const [billing_address, setBilling_Address] = useState("");
  const [billing_city, setBilling_City] = useState("");
  const [billing_district, setBilling_District] = useState("");
  const [billing_state, setBilling_State] = useState("");
  const [billing_pin, setBilling_Pin] = useState("");
  const [sameAsBilling, setSameasBilling] = useState(false);

 
  const [shipping_address, setShipping_Address] = useState("");
  const [shipping_city, setShipping_City] = useState("");
  const [shipping_district, setShipping_District] = useState("");
  const [shipping_state, setShipping_State] = useState("");
  const [shipping_pin, setShipping_Pin] = useState("");



  const [products, setProducts] = useState([]);
  const his = useHistory();



  const getCartDetails = async () => {
    // console.log(cart)

    setPreload(true);
    let data = JSON.stringify({ ids: Object.keys(cart.items) });
    // console.log(data)
    const res = await axios.post(
      `${apilink}/api/product/getCartDetails`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    // console.log(res.data.result)
    if (res.data.success) {
      setProducts(res.data.result);
    } else {
      his.push("/something-wrong");
    }
    setTimeout(() => {
      setPreload(false);
    }, 3000);
  };

  useEffect(async () => {
    if (token) {
      const res = await axios.get(`${apilink}/api/user/authVerify`, {
        headers: {
          Authorization: token,
        },
      });
      if (res.data.success) {
        setName(res.data.userInfo?.name);
        setEmail(res.data.userInfo?.email);
        setPhone(res.data.userInfo?.phone);
      }else {
        his.push("/login");
      }
    } else {
      his.push("/login");
    }
  }, []);

  const getAllorderaddressById=async()=>{
    const res=await axios.get( `${apilink}/api/orderaddress/getAllorderaddressById`,{
      headers: {
        Authorization: token,
      },
    })
    // console.log(res.data)
    setAddresslist(res.data.result)
   
  }

  const onOrderPlaced = async (e) => {
    e.preventDefault();
    setLoading(true);

    const data = {
        totalamount,
        amountwith_product , 
      amountwith_tax, 
      amountwith_shipping,
        order_customer: curaddress.address_customer,
        order_name:curaddress.address_name,
        order_email:curaddress.address_email,
        order_phone:curaddress.address_phone,
        order_gstin:curaddress.address_gstin,
        billing_address:curaddress.billing_address,
        
        shipping_address:curaddress.shipping_address,
        
        products,
        cart,
      };
      // console.log(data)
      
    
    const res = await axios.post(`${apilink}/api/order/addOrders`, data, {
      headers: {
        Authorization: token,
      },
    });
    // console.log(res.data)

    if (res.data.success) {
        
      setTimeout(() => {
          alert.success(res.data.msg);
          setLoading(false);
          
        }, 1500);

    setTimeout(() => {
       his.push("/orders");       
       setCart({});
    }, 3000);
  } else {
    if (res.data.msg == "Invalid Authentication.") {
      setTimeout(() => {
        setLoading(false);
        Cookies.remove("_indiayour_client_access_token");
        localStorage.removeItem("_indiayour_client_access_login");
        console.clear();
        window.location.href = "/login";
      }, 3000);
    } else {
      
      alert.error("Something wrong");
      setTimeout(() => {
          
        his.push("/something-wrong");
        setLoading(false);
      }, 3000);
    }
  }
    
  };




  const onAddAddress = async (e) => {
    e.preventDefault();
    setLoading(true);

    const data = {
       
        address_customer: customer,
        address_name:name,
        address_email:email,
        address_phone:phone,
        address_gstin:gstin,
        billing_address:{
            b_address:billing_address,
            b_city:billing_city,
            b_district:billing_district,
            b_state:billing_state,
            b_pin:billing_pin,
        },
        
        shipping_address:{
           s_address: shipping_address,
        s_city:shipping_city,
        s_district:shipping_district,
        s_state:shipping_state,
        s_pin:shipping_pin,
        },
        
       
      };
    //   console.log(data)
      
    
    const res = await axios.post(`${apilink}/api/orderaddress/postorderaddress`, data, {
      headers: {
        Authorization: token,
      },
    });
    // console.log(res.data)
    if (res.data.success) {
        
        setTimeout(() => {
            alert.success(res.data.msg);
            setLoading(false);
            setAddressview(false)
          }, 1500);

        
     getAllorderaddressById()

    } else {
      if (res.data.msg == "Invalid Authentication.") {
        setTimeout(() => {
          setLoading(false);
          Cookies.remove("_indiayour_client_access_token");
          localStorage.removeItem("_indiayour_client_access_login");
          console.clear();
          window.location.href = "/login";
        }, 3000);
      } else {
        
        alert.error("Something wrong");
        setTimeout(() => {
            
          his.push("/something-wrong");
          setLoading(false);
        }, 3000);
      }
    }
  };

  

  useEffect(() => {
    if (cart.Totalitems > 0) {
      getCartDetails();
      getAllorderaddressById()
    }else{
      his.push("/something-wrong")
    }
  }, []);

  useEffect(()=>{

    if(sameAsBilling)
    {
        setShipping_Address(billing_address)
        setShipping_State(billing_state)
        setShipping_District(billing_district)
        setShipping_City(billing_city)
        setShipping_Pin(billing_pin)

    }else{
        setShipping_Address("")
        setShipping_State("")
        setShipping_District("")
        setShipping_City("")
        setShipping_Pin("")
    }

  },[sameAsBilling , billing_address, billing_city, billing_district, billing_state,billing_pin,])

  const getQty = (productId) => {
    return cart.items[productId];
  };

  let total = 0;
  let total1 = 0;

  useEffect(() => {
    products.forEach((el) => {
      total =
        total +
        (gstPriceFunc(el.pro_price,el.pro_discount,el.pro_gst_percentage) *getQty(el._id));
      
        total1 = total1 + (discountPriceFunc(el.pro_price,el.pro_discount)) * getQty(el._id);
      // console.log(total)
    });
   
    // setTotalAmount(total  );


    setAmountwithProduct(total1);
    setAmountwithTax(total)

    setTotalAmount(total + amountwith_shipping);
  }, [products, cart]);
// console.log(curaddress)

  return (
    <>
      <div className="cart">
        <div className="container-fluid">
          <h3 className="text-center text-custom mb-4">Generate Your Instant Quotation</h3>

        <div className="row">
          <div className="col-lg-10  mx-auto">
          {
            addresslist.length > 0 ?(
              <>
               <div className="text-right mt-2 mb-3">
                <button className="btn btn-primary" onClick={()=>{
                  setAddressview((p)=>!p)
                }}>+ Add Address</button>
                </div>
                {
          addressview && (
            <>
            <form onSubmit={onAddAddress}>

<div className="row">
 
  <div className="col-12 mx-auto">
    <div className="card">
      <div className="card-header">
        <p className="m-0">Contact Details</p>
      </div>
      <div className="card-body">
      <div className="form-row ">
          <div class="form-group col-lg-4 col-md-6 col-sm-6 col-12">
            <input
              type="text"
              placeholder="Customer or Person"
              class="form-control"
              name="customer"
              value={customer}
              onChange={(e)=>setCustomer(e.target.value)}
              required
              autoComplete="nope"

            />
          </div>
          <div class="form-group col-lg-4 col-md-6 col-sm-6 col-12">
            <input
              type="text"
              placeholder="Email Address"
              class="form-control"
              name="email"
              value={email}
              onChange={(e)=>setEmail(e.target.value)}
              required
              readOnly
              
            />
          </div>
          <div class="form-group col-lg-4 col-md-6 col-sm-6 col-12">
            <input
              type="text"
              placeholder="Phone Number"
              class="form-control"
              autoComplete="nope"
              value={phone}
              onChange={(e)=>setPhone(e.target.value)}
              required
              
            />
          </div>
          <div class="form-group col-lg-4 col-md-6 col-sm-6 col-12">
            <input
              type="text"
              placeholder="Full Name"
              class="form-control"
              autoComplete="nope"
              value={name}
              onChange={(e)=>setName(e.target.value)}
              required
              
            />
          </div>
        </div>
      </div>
    </div>

    <div className="card mt-3">
      <div className="card-header">
        <p className="m-0">Billing Details</p>
      </div>
      <div className="card-body">
      <div className="form-row ">
          <div class="form-group col-lg-4 col-md-6 col-sm-6 col-12">
            <input
              type="text"
              placeholder="Enter GSTIN"
              class="form-control"
              name="gstin"
              value={gstin}
              onChange={(e)=>setGstin(e.target.value)}
              required
              autoComplete="nope"
            />
          </div>
          <div class="form-group col-lg-4 col-md-6 col-sm-6 col-12">
            <input
              type="text"
              placeholder="Enter Address"
              class="form-control"
              name="address"
              value={billing_address}
              onChange={(e)=>setBilling_Address(e.target.value)}
              required
              autoComplete="nope"
              
            />
          </div>
          <div class="form-group col-lg-4 col-md-6 col-sm-6 col-12">
          <select
                class="form-control form_h"
                value={billing_state}
                onChange={(e) => {
                  setBilling_State(e.target.value)
                  let ar=stateList.filter((v)=>v.state==e.target.value)
                  setDistrictList(ar[0].districts)
                }}
                required
              >
                <option selected hidden value="">
                  --Choose--
                </option>
                {
                  stateList?.map((v)=>{
                      return(
                          <>
                          <option value={v.state}>{v.state}</option>
                          </>
                      )
                  })
                }
                
              </select>
          </div>
          <div class="form-group col-lg-4 col-md-6 col-sm-6 col-12">
          <select
                class="form-control form_h"
                value={billing_district}
                onChange={(e) => setBilling_District(e.target.value)}
                required
              >
                <option selected hidden value="">
                  --Choose--
                </option>
                {
                  districtList?.map((v)=>{
                      return(
                          <>
                          <option value={v}>{v}</option>
                          </>
                      )
                  })
                }
                
              </select>
          </div>
          <div class="form-group col-lg-4 col-md-6 col-sm-6 col-12">
            <input
              type="text"
              placeholder="Enter City"
              class="form-control"
              name="city"
              value={billing_city}
              onChange={(e)=>setBilling_City(e.target.value)}
              required
              autoComplete="nope"
            />
          </div>
          <div class="form-group col-lg-4 col-md-6 col-sm-6 col-12">
            <input
              type="text"
              placeholder="Enter Pincode"
              class="form-control"
              name="pincode"
              value={billing_pin}
              onChange={(e)=>setBilling_Pin(e.target.value)}
              required
              autoComplete="nope"
            />
          </div>
        </div>
      </div>
    </div>
    <div className="card mt-3">
      <div className="card-header">
        <p className="m-0">Shipping Details</p>
      </div>
      <div className="card-body">
      <div class="form-check">
        <label class="form-check-label">
          <input
            type="checkbox"
            class="form-check-input"
            defaultChecked={sameAsBilling}
            onChange={() => setSameasBilling((state) => !state)}
            
          />
          Same as Billing Address
        </label>
      </div>
      <div className="form-row mt-4">
          
          <div class="form-group col-lg-4 col-md-6 col-sm-6 col-12">
            <input
              type="text"
              placeholder="Enter Address"
              class="form-control"
              name="address"
              value={shipping_address}
              onChange={(e)=>setShipping_Address(e.target.value)}
              required
              autoComplete="nope"
              
            />
          </div>
          <div class="form-group col-lg-4 col-md-6 col-sm-6 col-12">
          <select
                class="form-control form_h"
                value={shipping_state}
                onChange={(e) => {
                  setShipping_State(e.target.value)
                  let ar=stateList.filter((v)=>v.state==e.target.value)
                  setSDistrictList(ar[0].districts)
                }}
                required
              >
                <option selected hidden value="">
                  --Choose--
                </option>
                {
                  stateList?.map((v)=>{
                      return(
                          <>
                          <option value={v.state}>{v.state}</option>
                          </>
                      )
                  })
                }
                
              </select>
          </div>
          <div class="form-group col-lg-4 col-md-6 col-sm-6 col-12">
          <select
                class="form-control form_h"
                value={shipping_district}
                onChange={(e) => setShipping_District(e.target.value)}
                required
              >
                <option selected hidden value="">
                  --Choose--
                </option>
                {
                  sdistrictList?.map((v)=>{
                      return(
                          <>
                          <option value={v}>{v}</option>
                          </>
                      )
                  })
                }
                
              </select>
          </div>
          <div class="form-group col-lg-4 col-md-6 col-sm-6 col-12">
            <input
              type="text"
              placeholder="Enter City"
              class="form-control"
              name="city"
              value={shipping_city}
              onChange={(e)=>setShipping_City(e.target.value)}
              required
              autoComplete="nope"
              
            />
          </div>
          <div class="form-group col-lg-4 col-md-6 col-sm-6 col-12">
            <input
              type="text"
              placeholder="Enter Pincode"
              class="form-control"
              name="pincode"
              value={shipping_pin}
              onChange={(e)=>setShipping_Pin(e.target.value)}
              required
              autoComplete="nope"
              
            />
          </div>
        </div>
      </div>
    </div>
    <div className="text-center mt-4">
      <button className={loading ? "btn btn-primary dis" :"btn btn-primary"} disabled={loading}>Save Address</button>
    </div>
    {loading && (
          <div className="text-center p-2">
            <CircularProgress color="error" size={35} />
          </div>
        )}
  </div>
  
</div>
</form>
            </>
          )
        }
              <form className="mt-4" onSubmit={onOrderPlaced}>
              {
                addresslist.map((val ,ind)=>{
                  return(
                    <>
<div className="card-header mb-3 p-2" key={ind}>
<div class="form-check ">
                              <label class="form-check-label p-1 mb-2">
                                <input
                                  type="radio"
                                  class="form-check-input"
                                  name="address"
                                  value={val._id}
                                  onChange={()=>{
                                    setCurAddress(val)
                                  }}
                                 
                                  required
                                />
                                {val.address_customer} &nbsp; (GST-{val.address_gstin})
                                <br />
                                <small className="text-custom"><u>Billing Address</u></small> <br />
                                {val.billing_address.b_address} , {val.billing_address.b_city} , {val.billing_address.b_district} , {val.billing_address.b_state}, {val.billing_address.b_address} , {val.billing_address.b_pin}
                                <br /> 
                                <small className="text-custom"><u>Shipping Address</u></small> <br />
                                {val.shipping_address.s_address} , {val.shipping_address.s_city} , {val.shipping_address.s_district} , {val.shipping_address.s_state}, {val.shipping_address.s_address} , {val.shipping_address.s_pin}
<br /> 
<small className="text-custom"><u>Contact Information</u></small> <br />
{val.address_email} , {val.address_phone}
                              </label>
                            </div>
        </div>

                    </>
                  )
                })
              }

<div className="text-center mt-4">
      <button className={loading ? "btn btn-primary dis" :"btn btn-primary"} disabled={loading}>Submit Request</button>
    </div>
    {loading && (
          <div className="text-center p-2">
            <CircularProgress color="error" size={35} />
          </div>
        )}
              </form>
             
             
              </>
            ):(
              <>
              <div className="card p-3 mb-3 text-center">
                <p className="m-0">No address found</p>
                <div className="text-right mt-2">
                <button className="btn btn-primary" onClick={()=>{
                  setAddressview((p)=>!p)
                }}>+ Add Address</button>
                </div>
              </div>
              {
          addressview && (
            <>
            <form onSubmit={onAddAddress}>

<div className="row">
 
  <div className="col-12 mx-auto">
    <div className="card">
      <div className="card-header">
        <p className="m-0">Contact Details</p>
      </div>
      <div className="card-body">
      <div className="form-row ">
          <div class="form-group col-lg-4 col-md-6 col-sm-6 col-12">
            <input
              type="text"
              placeholder="Customer or Person"
              class="form-control"
              name="customer"
              value={customer}
              onChange={(e)=>setCustomer(e.target.value)}
              required
              autoComplete="nope"

            />
          </div>
          <div class="form-group col-lg-4 col-md-6 col-sm-6 col-12">
            <input
              type="text"
              placeholder="Email Address"
              class="form-control"
              name="email"
              value={email}
              onChange={(e)=>setEmail(e.target.value)}
              required
              readOnly
              
            />
          </div>
          <div class="form-group col-lg-4 col-md-6 col-sm-6 col-12">
            <input
              type="text"
              placeholder="Phone Number"
              class="form-control"
              autoComplete="nope"
              value={phone}
              onChange={(e)=>setPhone(e.target.value)}
              required
              
            />
          </div>
          <div class="form-group col-lg-4 col-md-6 col-sm-6 col-12">
            <input
              type="text"
              placeholder="Full Name"
              class="form-control"
              autoComplete="nope"
              value={name}
              onChange={(e)=>setName(e.target.value)}
              required
              
            />
          </div>
        </div>
      </div>
    </div>

    <div className="card mt-3">
      <div className="card-header">
        <p className="m-0">Billing Details</p>
      </div>
      <div className="card-body">
      <div className="form-row ">
          <div class="form-group col-lg-4 col-md-6 col-sm-6 col-12">
            <input
              type="text"
              placeholder="Enter GSTIN"
              class="form-control"
              name="gstin"
              value={gstin}
              onChange={(e)=>setGstin(e.target.value)}
              required
              autoComplete="nope"
            />
          </div>
          <div class="form-group col-lg-4 col-md-6 col-sm-6 col-12">
            <input
              type="text"
              placeholder="Enter Address"
              class="form-control"
              name="address"
              value={billing_address}
              onChange={(e)=>setBilling_Address(e.target.value)}
              required
              autoComplete="nope"
              
            />
          </div>
          <div class="form-group col-lg-4 col-md-6 col-sm-6 col-12">
          <select
                class="form-control form_h"
                value={billing_state}
                onChange={(e) => {
                  setBilling_State(e.target.value)
                  let ar=stateList.filter((v)=>v.state==e.target.value)
                  setDistrictList(ar[0].districts)
                }}
                required
              >
                <option selected hidden value="">
                  --Choose--
                </option>
                {
                  stateList?.map((v)=>{
                      return(
                          <>
                          <option value={v.state}>{v.state}</option>
                          </>
                      )
                  })
                }
                
              </select>
          </div>
          <div class="form-group col-lg-4 col-md-6 col-sm-6 col-12">
          <select
                class="form-control form_h"
                value={billing_district}
                onChange={(e) => setBilling_District(e.target.value)}
                required
              >
                <option selected hidden value="">
                  --Choose--
                </option>
                {
                  districtList?.map((v)=>{
                      return(
                          <>
                          <option value={v}>{v}</option>
                          </>
                      )
                  })
                }
                
              </select>
          </div>
          <div class="form-group col-lg-4 col-md-6 col-sm-6 col-12">
            <input
              type="text"
              placeholder="Enter City"
              class="form-control"
              name="city"
              value={billing_city}
              onChange={(e)=>setBilling_City(e.target.value)}
              required
              autoComplete="nope"
            />
          </div>
          <div class="form-group col-lg-4 col-md-6 col-sm-6 col-12">
            <input
              type="text"
              placeholder="Enter Pincode"
              class="form-control"
              name="pincode"
              value={billing_pin}
              onChange={(e)=>setBilling_Pin(e.target.value)}
              required
              autoComplete="nope"
            />
          </div>
        </div>
      </div>
    </div>
    <div className="card mt-3">
      <div className="card-header">
        <p className="m-0">Shipping Details</p>
      </div>
      <div className="card-body">
      <div class="form-check">
        <label class="form-check-label">
          <input
            type="checkbox"
            class="form-check-input"
            defaultChecked={sameAsBilling}
            onChange={() => setSameasBilling((state) => !state)}
            
          />
          Same as Billing Address
        </label>
      </div>
      <div className="form-row mt-4">
          
          <div class="form-group col-lg-4 col-md-6 col-sm-6 col-12">
            <input
              type="text"
              placeholder="Enter Address"
              class="form-control"
              name="address"
              value={shipping_address}
              onChange={(e)=>setShipping_Address(e.target.value)}
              required
              autoComplete="nope"
              
            />
          </div>
          <div class="form-group col-lg-4 col-md-6 col-sm-6 col-12">
          <select
                class="form-control form_h"
                value={shipping_state}
                onChange={(e) => {
                  setShipping_State(e.target.value)
                  let ar=stateList.filter((v)=>v.state==e.target.value)
                  setDistrictList(ar[0].districts)
                }}
                required
              >
                <option selected hidden value="">
                  --Choose--
                </option>
                {
                  stateList?.map((v)=>{
                      return(
                          <>
                          <option value={v.state}>{v.state}</option>
                          </>
                      )
                  })
                }
                
              </select>
          </div>
          <div class="form-group col-lg-4 col-md-6 col-sm-6 col-12">
          <select
                class="form-control form_h"
                value={shipping_district}
                onChange={(e) => setShipping_District(e.target.value)}
                required
              >
                <option selected hidden value="">
                  --Choose--
                </option>
                {
                  districtList?.map((v)=>{
                      return(
                          <>
                          <option value={v}>{v}</option>
                          </>
                      )
                  })
                }
                
              </select>
          </div>
          <div class="form-group col-lg-4 col-md-6 col-sm-6 col-12">
            <input
              type="text"
              placeholder="Enter City"
              class="form-control"
              name="city"
              value={shipping_city}
              onChange={(e)=>setShipping_City(e.target.value)}
              required
              autoComplete="nope"
              
            />
          </div>
          <div class="form-group col-lg-4 col-md-6 col-sm-6 col-12">
            <input
              type="text"
              placeholder="Enter Pincode"
              class="form-control"
              name="pincode"
              value={shipping_pin}
              onChange={(e)=>setShipping_Pin(e.target.value)}
              required
              autoComplete="nope"
              
            />
          </div>
        </div>
      </div>
    </div>
    <div className="text-center mt-4">
      <button className={loading ? "btn btn-primary dis" :"btn btn-primary"} disabled={loading}>Save Address</button>
    </div>
    {loading && (
          <div className="text-center p-2">
            <CircularProgress color="error" size={35} />
          </div>
        )}
  </div>
  
</div>
</form>
            </>
          )
        }
              
              </>
            )
          }
          </div>
        </div>
        

       
        </div>
      </div>


      {preload && (
        <>
          <div className="pageloading">
            <div class="lds-spinner">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SubmitRequest;
