import React from 'react'
import { path } from '../../data/fdata'
import './Slider.css'
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min'
const Ads = ({adsList}) => {
  return (
    <>
    <div className="container ">
        <div className="row ">
          {
            adsList.filter((v)=>v.adsType=="TWO").slice(0,2).map((val,ind)=>{
              return (
                <>
                <div className="col-md-6 col-12 mb-3" key={ind}>
               <a href={val.adslink} target="_blank">

               <img src={val.adsImg} alt="" className='ads_img' />
               {/* <div style={{ backgroundImage: `url('https://cdn.moglix.com/cms/flyout/Images_2024-01-05_18-04-20_Hillson_Gold_desktop.jpg')` , 
               width:'100%',
               height:'150px',
               backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    borderRadius: '8px',
      }}></div> */}
               </a>
            </div>
                </>
              )
            })
          }
            
           
        </div>
    </div>
    </>
  )
}

export default Ads