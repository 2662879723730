import React, { useState } from 'react'
import './Account.css'
import { NavLink ,  useHistory  } from 'react-router-dom/cjs/react-router-dom.min'
import { CircularProgress } from '@mui/material'
import Cookies from 'js-cookie'
import { apilink } from '../../data/fdata'
import axios from 'axios';
import { useEffect } from 'react'
import { useContext } from 'react'
import { DataContext } from '../../context/DataContext'


const Account = () => {
  const token = Cookies.get('_indiayour_client_access_token');
  const his = useHistory();

  const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [coll, setColl] = useState(false);
  const [msg, setMsg] = useState('');
  const {  alert } = useContext(DataContext);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  

  const [verifyshow, setVerifyShow] = useState(false);
  const [enterOtp, setEnterOtp] = useState('');
  const [genOtp, setGenOtp] = useState('');

  const [userData, setUserData] = useState([]);  
  const [pageloading, setPageLoading] = useState(false);


const onSubForm=async(e)=>{
  e.preventDefault()
setLoading(true)
  if (phone.length == 10) {

    if(enterOtp.length==0)
  {
// api call 

    setVerifyShow(true)
    const res=await axios.post(`${apilink}/auth/opt-verify`,{
      phonenumber:phone
    })
    // console.log(res.data)

   if(res.data.success)
   {
    setGenOtp(res.data.otp);
alert.success(res.data.msg)
    
   }else{
    his.push("/something-wrong")
   }
    
  }else{
    if(enterOtp != genOtp)
    {
      setTimeout(() => {
        setMsg('Wrong OTP');
      setStatus(true);
      }, 2000);
    }else{

      // submit api 

      const res=await axios.post(`${apilink}/api/user/updateUser`,{
        name,phone
      },{
        headers: {
          Authorization: token,
        },
      })

      
      if(res.data.success)
      {
        alert.success(res.data.msg)
        setTimeout(() => {
          window.location.reload()
        }, 3000);
      }else{
        alert.error(res.data.msg)
      }


    }
  }

  }else {
      
      setTimeout(() => {
        setMsg('Enter Phone No. & number should be 10 digit ');
      setStatus(true);
      }, 2000);
    
    }

    setTimeout(() => {
      setLoading(false)
    }, 2000);
  
}



useEffect(async() => {
  if(token) {
    setPageLoading(true)
      const res=await axios.get(`${apilink}/api/user/authVerify`,{
          headers: {
            Authorization: token,
          },
        })
        if(!res.data.success)
        {
          Cookies.remove('_indiayour_client_access_token');
         localStorage.removeItem('_indiayour_client_access_login');
        console.clear();
        window.location.href = '/login';
        }else{
          // console.log(res.data.userInfo)
          setUserData(res.data.userInfo)
          setName(res.data.userInfo?.name)
          setEmail(res.data.userInfo?.email)
          setPhone(res.data.userInfo?.phone)



        }
        setTimeout(() => {
          setPageLoading(false)
        }, 2000);
       
  }else{
    his.push("/login")
  }
}, []);

  return (
    <>
    <div className="account">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="card p-3">
            <div className="user_det">
          <img src="https://static-assets-web.flixcart.com/fk-p-linchpin-web/fk-cp-zion/img/profile-pic-male_4811a1.svg" alt="" />
          <div className='ml-2'>
            <p className='m-0'>Hello,</p>
            <h5>{name && name}</h5>
          </div>
        </div>
        <hr />
       <div className="text-right">
       <NavLink to="/orders" className="btn btn-primary">Orders</NavLink>
       </div>

        <div className="pt-3">
        {status ? (
                  <>
                    <div
                      className={
                        coll
                          ? 'alert alert-success alert-dismissible'
                          : 'alert alert-warning alert-dismissible'
                      }
                    >
                      <button
                        type="button"
                        className="close"
                        data-dismiss="alert"
                        onClick={() => setStatus(false)}
                      >
                        &times;
                      </button>
                      {msg}
                    </div>
                  </>
                ) : null}
        <form className='user_form mb-4' onSubmit={onSubForm}>

        <div class="form-group">
  <label for="usr">Name:</label>
  <input type="text" class="form-control" value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                      autoComplete="off" />
</div>
<div class="form-group">
  <label for="usr">Email:</label>
  <input type="email" class="form-control" value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      autoComplete="off" readOnly />
</div>
<div class="form-group">
  <label for="usr">Phone:</label>
  <input type="number" class="form-control" value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      required
                      maxLength={10}
                      autoComplete="off" />
</div>
{
  verifyshow && <div class="form-group">
  <label for="usr">Enter Otp:</label>
  <input type="text" class="form-control" value={enterOtp}
                      onChange={(e) => setEnterOtp(e.target.value)}
                      required
                      autoComplete="off" />
</div>
}

<div className="text-left">
                    <button
                      type="submit"
                      className={
                        loading ? 'dis btn btn-primary' : 'btn btn-primary'
                      }
                      disabled={loading}
                    >
                      Update Details
                    </button>
                  </div>
                  {loading && (
                    <div className="text-left p-2">
                      <CircularProgress color="error" size={35} />
                    </div>
                  )}
        </form>

        </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    {
        pageloading && (
          <>
          <div className="pageloading">
          <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          </div>

          </>
        )
      }
    </>
  )
}

export default Account