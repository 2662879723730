import React from 'react'
import { useContext } from 'react';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min'
import { DataContext } from '../../context/DataContext';

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

const AddToCartCard = ({pid,image,title,acprice,acdiscount,price,gstpercentage,gstprice,handleDelete,increment,decrement,getQty,getSum, pro_metadetails}) => {
  const { removeSpecialCharacters , location } = useContext(DataContext);

  
  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }}  placement="bottom-end"/>
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 200,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));

  return (
    <>
    
    <div className="cart_item">
                      <NavLink target="_blank" to={`/product/${removeSpecialCharacters(title)?.trim().toLowerCase().replace(/\s/g, "-")}/${pid}/${location}`} >
                      {
                        image.length > 0 && <img
                        src={image[0]}
                        alt={pro_metadetails?.img_alt.length > 0 ? pro_metadetails?.img_alt : title }
                        draggable="false"
                        className="cart_img"
                      />
                      }
                       </NavLink>
                      <div className="cart_text_box">
                       <NavLink target="_blank" to={`/product/${removeSpecialCharacters(title)?.trim().toLowerCase().replace(/\s/g, "-")}/${pid}/${location}`}>
                       <p className="m-0">
                          {title}
                        </p>
                        <h5>₹{gstprice} x {getQty(pid)} = <span className='text-custom'>₹{getSum(pid,gstprice)}</span> &nbsp; <HtmlTooltip
        title={
          <>
          
          <div className="tool_div">
            <div className="flex_tool">
              
          <p className='m-0 '>Actual Price: </p>
          <p className='m-0'>₹ {acprice} </p>
          

            </div>
            <div className="flex_tool">
              
          <p className='m-0'>{acdiscount}% OFF Price: </p>
          <p className='m-0'>₹ {price} </p>
          

            </div>
            <div className="flex_tool">
              
              <p className='m-0'>{gstpercentage}% GST Price: </p>
              <p className='m-0'>₹ {gstprice} </p>
              
    
                </div>
            
          </div>
          
          </>
        }
      >
        <i className='fa fa-info-circle text-custom'></i>
      </HtmlTooltip> </h5>
                       </NavLink>
                       {/* <h5 className='fn_12'>{gstpercentage}% GST Price: ₹{gstprice} x {getQty(pid)} = <span className='text-custom'>₹{getSum(pid,gstprice)}</span></h5> */}

                        <div className="d-flex">
                          <button className="btn btn-primary m-1" onClick={()=>decrement(pid)}><i class='bx bx-minus'></i></button>
                          <input type="text" value={getQty(pid)} readOnly />
                          <button className="btn btn-primary m-1" onClick={()=>increment(pid)}><i class='bx bx-plus'></i></button>
                        </div>
                      </div>
                     
                     
                     
                      <div className="sm_cross_box" onClick={()=>handleDelete(pid)}>
                        <i className="fa fa-trash-o"></i>
                      </div>
                    </div>
    </>
  )
}

export default AddToCartCard