import React from 'react'
import './Product.css'
import { NavLink , useHistory } from 'react-router-dom'
import TopRate from '../../component/slider/TopRate'
import {  apilink, path } from '../../data/fdata'
import { useContext } from 'react'
import { DataContext } from '../../context/DataContext'
import { useState } from 'react'
import axios from 'axios';
import { useEffect } from 'react'
const AllCategory = () => {
    const { categoryList , removeSpecialCharacters } = useContext(DataContext);
    const [dataList,setDataList]=useState([])
   const [preload,setPreload]=useState(false)
  
    const his = useHistory();


    const getalldata =async()=>{
        setPreload(true)
        const res=await axios.get(`${apilink}/api/product/getRandomProduct`)
        if(res.data.success)
        {
          setDataList(res.data.result)
    
        }else{
    
          his.push("/something-wrong")
    
        }
        setTimeout(() => {
            setPreload(false)
          }, 3000);
      }


      useEffect(()=>{
    
        getalldata()
       
    
      },[])

  return (
    <>
    <div className="product">
        <div className="container-fluid">
            
            <div className="row">
               {
                categoryList.map((val,ind)=>{
                    return(
                        <>
                         <div className="col-xl-2 col-lg-3 col-md-3 col-sm-4 col-6 mb-3" key={ind}>
                    <div className="card p-3 cat_card">
                        <NavLink to={`/category/${removeSpecialCharacters(val.cat_name).trim().toLowerCase().replace(/\s/g, "-")}/${val._id}`}>
                        <img src={val.cat_image} alt="" className='cat_img' draggable="false" />
                        <div className="p-1 text-center">
                            <p className='m-0'>{val.cat_name}</p>
                        </div>
                        </NavLink>
                    </div>
                </div>
                        
                        </>
                    )
                })
               }
                
            </div>
            
        </div>
    </div>
    <TopRate list={dataList} text="Best Sellers"  cat_id=""  preload={preload} />
    {
        preload && (
          <>
          <div className="pageloading">
          <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          </div>

          </>
        )
      }
    </>
  )
}

export default AllCategory


