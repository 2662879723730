import React, { useState } from "react";
import "./Cart.css";
import { NavLink, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import AddToCartCard from "../../component/cardcompo/AddToCartCard";
import { apilink, path } from "../../data/fdata";
import { useEffect } from "react";
import { useContext } from "react";
import { DataContext } from "../../context/DataContext";
import axios from "axios";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { CircularProgress } from "@mui/material";
import Cookies from "js-cookie";

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

const Cart = () => {
  const token = Cookies.get('_indiayour_client_access_token');
  const [amount, setAmount] = useState(0);
  const [gst, setGst] = useState(0);
  const [shipping, setShipping] = useState(0);
  const [totalamount, setTotalAmount] = useState(0);
  const [preload, setPreload] = useState(false);
  const [isLOGGEDIN, setIsLOGGEDIN] = useState(false);
  const [userData, setUserData] = useState([]);  
  const [priceData, setPriceData] = useState([]);  


  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [district, setDistrict] = useState("");

  const [loading, setLoading] = useState(false);
  

  const [quotePopUp, setQuotePopUp] = useState(false);
  const [products, setProducts] = useState([]);
  const his = useHistory();

  const { cart, setCart, alert , discountPriceFunc , gstPriceFunc , contactData } = useContext(DataContext);

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }}  placement="bottom-end"/>
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 200,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));

  const getCartDetails = async () => {
    // console.log(cart)

    setPreload(true);
    let data = JSON.stringify({ ids: Object.keys(cart.items) });
    // console.log(data)
    const res = await axios.post(
      `${apilink}/api/product/getCartDetails`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    // console.log(res.data.result)
    if (res.data.success) {
      setProducts(res.data.result);
    } else {
      his.push("/something-wrong");
    }
    setTimeout(() => {
      setPreload(false);
    }, 3000);
  };

  const handleDelete = (productId) => {
    const _cart = { ...cart };
    const qty = _cart.items[productId];
    delete _cart.items[productId];
    _cart.Totalitems -= qty;
    setCart(_cart);
    const updatedProductsList = products.filter(
      (product) => product._id !== productId
    );
    setProducts(updatedProductsList);
  };

  const increment = (productId) => {
    const existingQty = cart.items[productId];
    if (existingQty === 5) {
      alert.error("Quantity never be greater than 5");
      return;
    }
    const _cart = { ...cart };
    _cart.items[productId] = existingQty + 1;
    _cart.Totalitems += 1;
    setCart(_cart);
  };

  const decrement = (productId) => {
    const existingQty = cart.items[productId];
    if (existingQty === 1) {
      alert.error("Quantity never be less than 1");
      return;
    }
    const _cart = { ...cart };
    _cart.items[productId] = existingQty - 1;
    _cart.Totalitems -= 1;
    setCart(_cart);
  };

  const getQty = (productId) => {
    return cart.items[productId];
  };
  const getSum = (productId, price) => {
    const sum = price * getQty(productId);

    return sum;
  };

  useEffect(() => {
    if (cart.Totalitems > 0) {
      getCartDetails();
    }
  }, []);

  let total = 0;
  let totalgst = 0;

  useEffect(() => {
    let arobj=[]
    products.forEach((el) => {
 
      totalgst+= gstPriceFunc(el.pro_price,el.pro_discount,el.pro_gst_percentage) *getQty(el._id);

      total = total + (discountPriceFunc(el.pro_price,el.pro_discount)) * getQty(el._id);
        
          let a={
            proimg:el.pro_images[0],
            prodiscount:el.pro_discount,
            prodisprice:discountPriceFunc(el.pro_price,el.pro_discount)  * getQty(el._id),
            proGST:el.pro_gst_percentage,
            proGSTprice:gstPriceFunc(el.pro_price,el.pro_discount,el.pro_gst_percentage)  * getQty(el._id)

          }
          arobj.push(a);
      // console.log(total)
    });
    setPriceData(arobj)
    setAmount(total);
    setGst(totalgst)
    setTotalAmount(totalgst+shipping);
  }, [products, cart]);



  useEffect(async() => {
    if(token) {
      
        const res=await axios.get(`${apilink}/api/user/authVerify`,{
            headers: {
              Authorization: token,
            },
          })
          if(res.data.success)
          {
            setUserData(res.data.userInfo)
            setPhone(res.data.userInfo?.phone)
            setIsLOGGEDIN(true)
          }
         
         
    }
  }, []);

  // console.log(priceData)

  return (
    <>
   

      <div className="cart">
        <div className="container-fluid">
          <div className="row">
           
            <div className="col-lg-10 mx-auto">
              {products.length > 0 ? (
                <>
                  <div className="row">
                    <div className="col-lg-8 col-md-8 col-12 mb-3">
                      <div className="card cart_card p-2">
                        <h4>My Cart</h4>
                        <hr />

                        {products?.map((val, ind) => {
                          return (
                            <>
                              {preload ? (
                                <>
                                  <SkeletonTheme
                                    baseColor="#f2f2f2"
                                    highlightColor="#fff"
                                    key={ind}
                                  >
                                    <Skeleton height={150} />
                                  </SkeletonTheme>
                                </>
                              ) : (
                                <>
                                  <AddToCartCard
                                    key={ind}
                                    image={val.pro_images}
                                    title={val.pro_title}
                                    pid={val._id}
                                    acprice={val.pro_price}
                                    acdiscount={val.pro_discount}
                                    price={ discountPriceFunc(val.pro_price,val.pro_discount) }
                                    gstpercentage={val?.pro_gst_percentage}
                                    gstprice={gstPriceFunc(val.pro_price,val.pro_discount,val.pro_gst_percentage)}
                                    handleDelete={handleDelete}
                                    increment={increment}
                                    decrement={decrement}
                                    getQty={getQty}
                                    getSum={getSum}
                                    pro_metadetails={val.pro_metadetails}
                                  />
                                </>
                              )}
                            </>
                          );
                        })}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-12 mb-3">
                      {
                        preload ?<>
                        <SkeletonTheme
                                    baseColor="#f2f2f2"
                                    highlightColor="#fff"
                                    
                                  >
                                    <Skeleton height={350} />
                                  </SkeletonTheme>
                        </>:<>
                        
                        <div className="position_sticky ">
                        <div className="card p-2">
                          <h4>Quote Summary</h4>
                          <hr />
                          <div className="dd_flex mb-3">
                            <p className="m-0">Total Amount</p>
                            <p className="m-0">
                            <HtmlTooltip
        title={
          <>
          
          <div className="tool_div">
           {
            priceData?.map((val,ind)=>{
              return(
                <div className="flex_toolp">
              
                <p className='m-0 '>
                 <img src={val.proimg} alt="" className="smimg" />  {val.prodiscount}% OFF Price
                   </p>
                <p className='m-0'>₹ {val.prodisprice} </p>
                
      
                  </div>
              )
            })
           }
           
           <div className="flex_toolp bord">
          
            <p></p>
            <p>₹ {amount}</p>
           </div>
            
          </div>
          
          </>
        }
      >
        <i className='fa fa-info-circle text-custom'></i>
      </HtmlTooltip> &nbsp; ₹{amount && amount?.toFixed(2)}  
                            </p>
                          </div>
                          <div className="dd_flex mb-3">
                            <p className="m-0">Total Amount with GST</p>
                            <p className="m-0"> 
                            <HtmlTooltip
        title={
          <>
          
          <div className="tool_div">
           {
            priceData?.map((val,ind)=>{
              return(
                <div className="flex_toolp">
              
                <p className='m-0 '>
                 <img src={val.proimg} alt="" className="smimg" />  {val.proGST}% GST Price
                   </p>
                <p className='m-0'>₹ {val.proGSTprice} </p>
                
      
                  </div>
              )
            })
           }
           
           <div className="flex_toolp bord">
          
            <p></p>
            <p>₹ {gst}</p>
           </div>
            
          </div>
          
          </>
        }
      >
        <i className='fa fa-info-circle text-custom'></i>
      </HtmlTooltip> &nbsp;₹{gst && gst?.toFixed(2)}</p>
                          </div>
                          <div className="dd_flex ">
                            <p className="m-0">Total Shipping</p>
                            <p className="m-0">
                              ₹{shipping && shipping?.toFixed(2)}
                            </p>
                          </div>
                          <hr />
                          <div className="dd_flex ">
                            <p className="m-0">Amount Payable</p>
                            <p className="m-0">
                              ₹{totalamount && totalamount.toFixed(2)}
                            </p>
                          </div>
                          <hr />
                          <button
                            className="btn btn-primary text-btn"
                            onClick={() => {
                              if(isLOGGEDIN)
                              {
                                document
                                .querySelector("#root")
                                .classList.add("disable-scroll");
                              setQuotePopUp(true);
                              }else{
                                alert.error("You are not authorized user, please login...")
                                setTimeout(() => {
                                  his.push("/login")
                                }, 1500);
                              }
                            }}
                          >
                            Order Now
                          </button>
                        </div>
                      </div>
                        </>
                      }
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div>
                    <img
                      src={`${path}/image/noitem.webp`}
                      alt=""
                      className="noitem_img"
                    />
                    <h5 className="text-center p-2">No Item Found</h5>
                    <div className="text-center">
                      <button
                        className="btn btn-primary"
                        onClick={() => his.push("/products")}
                      >
                        Continue Shopping
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="col-12 mt-4">

<p className="fn_16 text-center  "><span className="p-2 rounded  bg-red">
Need help? Call at <a className=" text-light" href={`tel:${contactData.phone}`} >
    { contactData.phone}
    
  </a>
</span>
</p>
</div>
          </div>
        </div>
      </div>

      {quotePopUp && (
        <div className="model_box">
          <div className="inner_model">
            <div
              className="cross"
              onClick={() => {
                document
                  .querySelector("#root")
                  .classList.remove("disable-scroll");
                setQuotePopUp(false);
              }}
            >
              &times;
            </div>
            {
              phone ? <>
              
              
              <div>
              <h4 className="pt-3  "> Hi , {userData?.name && userData.name } </h4>
              <p>Please provide all contact , billing & shipping details <span className="text-custom cur" onClick={()=>{
                his.push("/submit-request")
                document
                  .querySelector("#root")
                  .classList.remove("disable-scroll");
                setQuotePopUp(false);
            }}>here</span></p>
            <button className="btn btn-primary" onClick={()=>{
                his.push("/submit-request")
                document
                  .querySelector("#root")
                  .classList.remove("disable-scroll");
                setQuotePopUp(false);
                
                }}>Click Here</button>
            </div>
              </> :<>
              <div className="text-center">
              <h4 className="pt-3  "> Hi , {userData?.name && userData.name } </h4>
              <p>Please Update Your Phone No . Go to <span className="text-custom cur" onClick={()=>{
                his.push("/account")
                document
                  .querySelector("#root")
                  .classList.remove("disable-scroll");
                  setQuotePopUp(false);
                }}>Account</span> </p>
              </div>
              
              </>
            }
          </div>
        </div>
      )}

      {preload && (
        <>
          <div className="pageloading">
            <div class="lds-spinner">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Cart;
