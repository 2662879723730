import React from 'react'
import { path } from '../data/fdata'
import { useHistory } from "react-router-dom"
const Wrong = () => {
    const his = useHistory();
  return (
    <>
    <div className="authbox">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-8 col-12 mx-auto">
                <img src={`${path}/image/error.png`} alt="" className='erro_img' />
                <h5 className='text-center p-2 mt-3'>Something Wrong</h5>
                <div className="text-center">
                    <button className='btn btn-primary' onClick={()=>his.push("/")}>Go Back</button>
                </div>
            </div>
            </div>
            </div>
            </div>
    </>
  )
}

export default Wrong