import React, { createContext, useState, useEffect } from 'react';
import { apilink } from '../data/fdata';
import axios from 'axios';
import Cookies from 'js-cookie';
import {  useHistory } from 'react-router-dom';
import { useAlert } from "react-alert";





export const DataContext = createContext();

const getLocalItmes = (val) => {
  let loc=localStorage.getItem(val)
  if(loc) 
  {
    return loc
  }else{
    return ""
  }
};

const getLocalCart=(val)=>{
  let list = localStorage.getItem(val);
  // console.log(list);

  if (list) {
    return JSON.parse(localStorage.getItem(val));
  } else {
    return {};
  }
}

const getLocalView=(val)=>{
  let list = localStorage.getItem(val);
  // console.log(list);

  if (list) {
    return JSON.parse(localStorage.getItem(val));
  } else {
    return [];
  }
}

export const ConText = (props) => {
  const alert = useAlert();
  const [cart, setCart] = useState(getLocalCart('indiayour_cart'));
  const [recentView, setRecentView] = useState(getLocalView('indiayour_recent_view'));

  const [location, setLocation] = useState(getLocalItmes('location'));

  const [showPop, setShowPop] = useState(false);
  const [categoryList,setCategoryList]=useState([])
  const [contactData,setContactData]=useState({})


  const token = Cookies.get('_indiayour_client_access_token');
  const his = useHistory();

  // const [position, setPosition] = useState({ latitude: null, longitude: null });

  


  useEffect(() => {
    localStorage.setItem('indiayour_cart', JSON.stringify(cart));
  }, [cart]);

  useEffect(() => {
    localStorage.setItem('location', location);
    setLocation(location)
  }, [location]);

  useEffect(() => {
    localStorage.setItem('indiayour_recent_view', JSON.stringify(recentView));
  }, [recentView]);

  useEffect(()=>{
        
        
    function disable(){
     
      document.querySelector('#root').classList.add('disable-scroll');
    }
// console.log(location )
if(!location.length)
{
    // setShowPop(true)
    // his.push("/")


    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        axios.get(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${position.coords.latitude}&lon=${position.coords.longitude}`)
      .then(response => {
        // Handle success
        console.log('Response:', response.data.address);
        // You can access the data from the response here
        if(response.data.address)
        {
          localStorage.setItem('location', response.data.address.city);
    setLocation(response.data.address.city);

        }else{
          localStorage.setItem('location', "Kolkata");
          setLocation("Kolkata");
        }
      })
      .catch(error => {
        // Handle error
        localStorage.setItem('location', "Kolkata");
    setLocation("Kolkata");
        console.error('Error:', error);
      });
        // setPosition({
        //   latitude: position.coords.latitude,
        //   longitude: position.coords.longitude,
        // });
      });
    } else {
      localStorage.setItem('location', "Kolkata");
    setLocation("Kolkata");
      console.log("Geolocation is not available in your browser.");
    }


    
    // disable()
}
    

},[])

  const addtocart = (id) => {
    let GlobalCart = { ...cart };
    if (!GlobalCart.items) {
      GlobalCart.items = {};
    }
    if (GlobalCart.items[id]) {
      GlobalCart.items[id] += 1;
    } else {
      GlobalCart.items[id] = 1;
    }
    if (!GlobalCart.Totalitems) {
      GlobalCart.Totalitems = 0;
    }
    GlobalCart.Totalitems += 1;

    setCart(GlobalCart);
  };
  const addtocartwithqty = (id, qty) => {
    let GlobalCart = { ...cart };
    if (!GlobalCart.items) {
      GlobalCart.items = {};
    }
    let qt = Number(qty);
    if (GlobalCart.items[id]) {
      GlobalCart.items[id] += qt;
    } else {
      GlobalCart.items[id] = qt;
    }
    if (!GlobalCart.Totalitems) {
      GlobalCart.Totalitems = 0;
    }
    GlobalCart.Totalitems += qt;

    setCart(GlobalCart);
  };

  const addtowishlist=async(id)=>{

       const res=await axios.post(`${apilink}/api/wishlist/addtowishlist`,{
        product_id:id
       },{
        headers: {
          Authorization: token,
        },
       })

       if(res.data.success) {
        alert.success(res.data.msg)
       }else{
        if(res.data.msg=="Invalid Authentication.")
        {
          alert.error("You are not authorized user, please login...")
         setTimeout(()=>{
          Cookies.remove('_indiayour_client_access_token');
          localStorage.removeItem('_indiayour_client_access_login');
          console.clear();
          window.location.href = '/login';
         },5000)
        }else if(res.data.msg=="Already present in your wishlist")
        {
          alert.error(res.data.msg)
          

        }
        
        else{
          alert.error("Something wrong")
          setTimeout(() => {
            his.push("/something-wrong")
          }, 3000);


        }
       }
  }


  const getallCategory = async()=>{
    const res=await axios.get(`${apilink}/api/category/getallCategory`)
    if(res.data.success)
    {
      setCategoryList(res.data.result)
    }
  }

  const getAllSocial=async()=>{
    const res=await axios.get(`${apilink}/api/contactlink/getContactLinks`)
    if(res.data.success)
    {
      setContactData(res.data.result)
      
    }
  }

  useEffect(()=>{
    getallCategory()
    getAllSocial()
  },[])


  function removeSpecialCharacters(text) {
    // Define a regular expression pattern to match special characters
    const regexPattern = /[&\/\\#,+()$~%.'":*?<>{}]/g;
  
    // Use the replace method to remove the special characters
    let cleanedText = text?.replace(regexPattern, '');
    cleanedText = cleanedText?.replace(/\s+/g, ' ');
  // console.log(cleanedText)
    return cleanedText;
  }

  const discountPriceFunc=(price,disc)=>{
    let discount = (disc * price) / 100;
    let  discountedAmount=(Math.ceil(price-discount))
    return discountedAmount;
  }
const gstPriceFunc=(price, discount , gst)=>{
  let total= discountPriceFunc(price,discount)
  
 let GSTamount= Math.ceil((total*gst)/100)
//  console.log(total , GSTamount)
  return total+GSTamount;

}

  return (
    <>
      <DataContext.Provider
        value={{contactData , removeSpecialCharacters , recentView ,setRecentView,  alert  , cart, setCart, addtocart, addtocartwithqty , showPop, setShowPop ,location , setLocation , categoryList , addtowishlist , gstPriceFunc , discountPriceFunc }}
      >
        {props.children}
      </DataContext.Provider>
    </>
  );
};
