import React from 'react'
import { useContext } from 'react';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min'
import { DataContext } from '../../context/DataContext';

const TopRateCard = ({pro_id,pro_img,pro_title,pro_price,pro_discount , pro_metadetails}) => {
  // console.log(pro_metadetails)
  const { addtocart , addtowishlist , removeSpecialCharacters , location } = useContext(DataContext);

  return (
    <><div  className="card p-2 top_card_link">
    <NavLink to={`/product/${removeSpecialCharacters(pro_title)?.trim().toLowerCase().replace(/\s/g, "-")}/${pro_id}/${location}`} target="_blank">
    
    {
      pro_img?.length && <img src={pro_img[0]} alt={pro_metadetails?.img_alt.length > 0 ? pro_metadetails?.img_alt : pro_title } className='card_img' draggable="false" />
    }
    <p className='top_p' title={pro_title}>{pro_title}</p>
    <h5 className='m-0'>₹ { Math.ceil(pro_price - (pro_price*(pro_discount/100)))}</h5>
    <p className='span_line'>Actual Price:<span style={{textDecoration:"line-through"}}> ₹{pro_price}</span></p>
    </NavLink>
   <div className="text-center">
   <button className='btn btn-outline-danger ml-1 mr-1' onClick={()=>addtocart(pro_id)}><i class='bx bx-cart-add'></i></button>
    <button className='btn btn-danger ml-1 mr-1' onClick={()=>addtowishlist(pro_id)}><i class='bx bx-heart'></i></button>
   </div>
   <div className="small_discount">
    {pro_discount}% Off
   </div>

   </div></>
  )
}

export default TopRateCard