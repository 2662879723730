import React from 'react'
import './Search.css'
import TopRateCard from '../../component/cardcompo/TopRateCard'
import { NavLink, useParams, useHistory , useLocation } from "react-router-dom";
import { useEffect } from 'react';
import { apilink } from '../../data/fdata';
import axios from 'axios';
import { useState } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const Search = () => {
  const his = useHistory();
  const [dataList,setDataList]=useState([])
  const [preload,setPreload]=useState(false)


    const search = useLocation().search;
    // console.log(search)
    const query = new URLSearchParams(search).get('query');
    // console.log(query)
    

    const getproducts=async()=>{
        setPreload(true)
        const res=await axios.get(`${apilink}/api/product/searchProducts/${query}`);
        // console.log(res.data)
        if(res.data.success)
        {
            setDataList(res.data.result)
        }

        setTimeout(() => {
            setPreload(false)
          }, 3000);

    }


    useEffect(() => {
        if(query)
        {
            getproducts();
        }else{
            his.push("/something-wrong")
        }
      }, [search]);

  return (
    <>
    <div className="search_page">
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-10 col-12 mx-auto">
                <h4 className='mb-3'>Search Results for <span className='text-custom'>{query}</span></h4>
                <div className="wish-flex">
           {
            dataList.length > 0 ?(
                <>
                {
                    dataList.map((val,ind)=>{
                        return(
                            <>
                            {
                                preload ?(
                                    <>
<SkeletonTheme
                            baseColor="#f2f2f2"
                            highlightColor="#fff"
                            key={ind}
                          >
                            
                              <div className="top_rate_nav_link">
                                <div className="top__slider">
                                  <Skeleton height={300} />
                                </div>
                              </div>
                            
                          </SkeletonTheme>
                                    </>
                                ):(
                                    <>
 <TopRateCard  pro_id={val._id} pro_img={val.pro_images}  pro_title={val.pro_title} pro_price={val.pro_price} pro_discount={val.pro_discount} />

                                    </>
                                )
                            }
                            </>
                        )
                    })
                }
                
                </>
            ):(
                <>
                <div className="p-3">
                    <p>No Item Found</p>
                </div>
                
                
                </>
            )
           }
        </div>
                </div>
            </div>
        </div>
    </div>
    
    </>
  )
}

export default Search