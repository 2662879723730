import React, { useContext } from "react";

import { useState } from "react";
import { CircularProgress } from "@mui/material";

import axios from "axios";
import { useAlert } from "react-alert";
import './Need.css'
import { apilink } from "../../data/fdata";
import { DataContext } from "../../context/DataContext";
const ContactUs = () => {

    const [loading, setLoading] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phoneNo, setPhoneNo] = useState("");
    const [message, setMessage] = useState("");
    const alert = useAlert();
  
  const {   contactData} = useContext(DataContext);

  
    const onSUb = async (e) => {
      e.preventDefault();
      setLoading(true);
  
      const res = await axios.post(`${apilink}/api/contact/postContact`, {
        fname: firstName,
        lname: lastName,
        phone: phoneNo,
        comment: message,
      });
  
      if(res.data.success) {
        setTimeout(() => {
          alert.success(res.data.msg)
        setFirstName("")
        setLastName("")
        setPhoneNo("")
        setMessage("")
        setLoading(false);
        }, 2000);
      }else{
        setTimeout(() => {
          alert.error(res.data.msg)
          setLoading(false);
        }, 2000);
      }
     
    };

  return (
    <>
    <div className="contact need">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-11 mx-auto mb-4 d-flex justify-content-center  flex-column">
              <h1 className="text-col title_font">Get in Touch</h1>
              <p>
                Need to get in touch with us? Either fill out the form with your
                inquiry or find the{" "}
                <a href={`mailto:${contactData.email}`}>department email</a> you'd
                like to contact below
              </p>
              <p className="m-0">Email: &nbsp;<a href={`mailto:${contactData.email}`}>{contactData.email}</a> </p>
              <p className="m-0">Phone: &nbsp;<a href={`tel:${contactData.phone}`}>{contactData.phone}</a> </p>

            </div>
            <div className="col-md-6 col-11 mx-auto mb-3">
              <div className="card p-3 contact_input">
                <h4>Fill the form</h4>
                <form onSubmit={onSUb} className="mt-4">
                  <div class="form-row">
                    <div class="form-group col-md-6">
                      <input
                        type="text"
                        class="form-control"
                        name="fname"
                        placeholder="Enter First Name"
                        value={firstName}
                        onChange={(e)=>setFirstName(e.target.value)}
                        required
                      />
                    </div>
                    <div class="form-group col-md-6">
                      <input
                        type="text"
                        class="form-control"
                        name="lname"
                        placeholder="Enter Last Name"
                        value={lastName}
                        onChange={(e)=>setLastName(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div class="form-group ">
                    <input
                      type="tel"
                      class="form-control"
                      name="phone"
                      placeholder="Enter Phone Number"
                      value={phoneNo}
                        onChange={(e)=>setPhoneNo(e.target.value)}
                      required
                    />
                  </div>
                  <div class="form-group">
                    <textarea
                      class="form-control"
                      rows="3"
                      id="comment"
                      name="msg"
                      placeholder="Write your query . . ."
                      value={message}
                        onChange={(e)=>setMessage(e.target.value)}
                      required
                    ></textarea>
                  </div>
                  <button
                    className={
                      loading ? "dis btn btn-primary" : "btn btn-primary"
                    }
                    disabled={loading}
                    type="submit"
                  >
                    Send Now
                  </button>
                  {loading && (
                    <div className="text-center p-2">
                      <CircularProgress color="error" size={40} />
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    
    </>
  )
}

export default ContactUs