import React, { useContext, useEffect, useState } from "react";
import "./PopUp.css";
import { placelist } from "../../data/fdata";
import { DataContext } from "../../context/DataContext";
import {  useHistory } from 'react-router-dom';

const PopUp = () => {
    const [place, setPlace] = useState('');
    const his = useHistory();

    const { showPop, setShowPop ,location , setLocation} = useContext(DataContext);
    
   useEffect(()=>{
    if(location)
    {
        setPlace(location);
   
    }
    
  } ,[location])

  const onLocation = async (e) => {
    e.preventDefault()
    document.querySelector('#root').classList.remove('disable-scroll');

    // localStorage.setItem(data)
    localStorage.setItem('location', place);
    setLocation(place)
    setShowPop(false)
    // window.history.back()
    //  setTimeout(() => {
    //   window.location.reload()
    //  }, 1000);
   
  };
  return (
    <>

    {
        showPop && <div className="model_box">
        <div className="inner_model">
          {/* <div className="cross" onClick={()=>{
            document.querySelector('#root').classList.remove('disable-scroll');
            setShowPop(false)}}>&times;</div> */}
          <form onSubmit={onLocation}>
            <div class="form-group">
              <label for="sel1">Select Place:</label>
              <select class="form-control"  value={place}
                          onChange={(e) => {
                            
                            setPlace(e.target.value);
                          }}
                          required>
                <option selected value="" hidden>
                  --Choose--
                </option>
                {placelist?.map((val, ind) => {
                  return (
                    <>
                      <option key={ind} value={val}>
                        {val}
                      </option>
                    </>
                  );
                })}
              </select>
            </div>
            
            <div className="text-center">
                      <button
                        type="submit"
                        className='btn btn-primary pl-5 pr-5'
                        
                        
                      >
                        Submit
                      </button>
                    </div>
          </form>
        </div>
      </div>
    }
      
    </>
  );
};

export default PopUp;
