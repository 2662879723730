import React from "react";
import "./Auth.css";
import { useState } from "react";
import { CircularProgress } from "@mui/material";
import { useHistory } from "react-router-dom";
import { apilink, path } from "../../data/fdata";
import axios from "axios";
import Cookies from 'js-cookie';
import { useParams } from "react-router-dom/cjs/react-router-dom.min";


const ResetPassword = () => {
  const token = Cookies.get('_indiayour_client_access_token');
    const [npassword, setNPassword] = useState("");
    const [cpassword, setCPassword] = useState("");
  const [ntype, setNtype] = useState("password");
  const [ctype, setCtype] = useState("password");


    const [status, setStatus] = useState(false);
    const [msg, setMsg] = useState("");
    const [loading, setLoading] = useState(false);
    const his = useHistory();

    const { accesstoken} = useParams();
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);
      
      if(npassword == cpassword)
      {
        console.log("ok")
        const res=await axios.post(`${apilink}/api/user/resetPassword`,{
          password: npassword
        },{
          headers: {
            Authorization: accesstoken,
          },
        })

        // console.log(res.data)

        if(res.data.success)
        {
          setMsg(res.data.msg)
          setStatus(true)

          setTimeout(() => {            
          setLoading(false)
          Cookies.remove('_indiayour_client_access_token');
    localStorage.removeItem('_indiayour_client_access_login');
    console.clear();
    window.location.href = '/login';
          }, 2000);

        }else{
          setTimeout(() => {
            setMsg(res.data.msg)
          setStatus(true)
          setLoading(false)
          }, 2000);
        }
        
      }else{
        setMsg("Password Not Matches")
        setStatus(true)
        setLoading(false)
       
      }
    };
  return (
    <>
    
    <div className="authbox">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-8 col-12 mx-auto">
              <div className="card p-3">
                <img
                  src={`${path}/image/Indiayour.png`}
                  alt=""
                  className="authbox_logo"
                />
                <h5 className="text-center">Reset Password</h5>
                
                {status && (
                  <div className="alert alert-warning alert-dismissible m-2">
                    <button
                      type="button"
                      className="close fn_14"
                      data-dismiss="alert"
                      onClick={()=>setStatus(false)}
                    >
                      &times;
                    </button>
                    {msg}
                  </div>
                )}
                <form onSubmit={handleSubmit} className="mt-3">
                  <div className="form-group passform">
                    <input
                      type={ntype}
                      placeholder="Enter New Password"
                      className="form-control"
                      name="password"
                      value={npassword}
                      onChange={(e) => setNPassword(e.target.value)}
                      required
                    />
                     <div>
                     {ntype == 'password' ?  <i className='fa fa-eye-slash cur' onClick={()=>setNtype("text")}></i> :  <i className='fa fa-eye cur' onClick={()=>setNtype("password")}></i> }
                     </div>
                  </div>
                  <div className="form-group passform">
                    <input
                      type={ctype}
                      placeholder="Confirm Password"
                      className="form-control"
                      name="password"
                      value={cpassword}
                      onChange={(e) => setCPassword(e.target.value)}
                      required
                    />
                     <div>
                     {ctype == 'password' ?  <i className='fa fa-eye-slash cur' onClick={()=>setCtype("text")}></i> :  <i className='fa fa-eye cur' onClick={()=>setCtype("password")}></i> }
                     </div>
                  </div>
                  
                  <div className="text-center mt-4">
                    <button
                      type="submit"
                      className={
                        loading
                          ? "dis btn btn-primary pl-5 pr-5"
                          : "btn btn-primary pl-5 pr-5"
                      }
                      disabled={loading}
                    >
                      Update Password
                    </button>
                  </div>
                  {loading && (
                    <div className="text-center p-2">
                      <CircularProgress color="error" size={35} />
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ResetPassword