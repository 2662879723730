import React from "react";
import "./Product.css";
import { NavLink, useParams, useHistory , useLocation } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { apilink, path } from "../../data/fdata";
import axios from "axios";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useContext } from "react";
import { DataContext } from "../../context/DataContext";
import { Helmet } from "react-helmet";

const CategoryDet = () => {
  const [dataList, setDataList] = useState([]);
  const [totaldataList,setTotalDataList]=useState([])
 

  const [subcatList, setSubcatList] = useState([]);
  const [filter, setFilter] = useState("All");

  const [preload, setPreload] = useState(false);
  const { addtocart  , categoryList , addtowishlist , removeSpecialCharacters , location } = useContext(DataContext);
  let { catslag, catid } = useParams();
  const his = useHistory();
  const search = useLocation().search;
  const query = new URLSearchParams(search).get('query');
  const subcategoryid = new URLSearchParams(search).get('sid');

  // console.log(query)

// console.log(query)
  const getProductsByCategoryId = async () => {
    setPreload(true);
    const res = await axios.get(
      `${apilink}/api/product/getProductsByCategoryId/${catid}`
    );
    // console.log(res.data)

    if (res.data.success) {
      if(subcategoryid?.length > 0)
      {
        let arr=res.data.result.filter((item) => item.pro_subcategory_id==subcategoryid)
        setDataList(arr);
        setTotalDataList(arr)
        // if(arr.length > 0)
        // {
          
        // }else{
        //   his.push("/something-wrong");
        // }
      }
     else{
      setDataList(res.data.result);
      setTotalDataList(res.data.result)
     }
    } else {
      his.push("/something-wrong");
    }
    setTimeout(() => {
      setPreload(false);
    }, 3000);
  };

  const getCategoryById=async()=>{

    const res=await axios.get(`${apilink}/api/category/getCategoryById/${catid}`)
    if(res.data.success)
    {
      setSubcatList(res.data.result?.sub_category)
    }else{
      his.push("/something-wrong");
    }
   
    // console.log(res.data.result)

  }

  const getSubCategoryById=async(id)=>{
    // console.log("sub")
    const res=await axios.get(`${apilink}/api/category/getSubCategoryById/${id}`)
    if(!res.data.success)
    {
      his.push("/something-wrong");
    }
  }

  useEffect(() => {
    if (catid) {
      getProductsByCategoryId();
      
     getCategoryById()
     
    }
    if(subcategoryid)
    {
      getSubCategoryById(subcategoryid)
    }
  }, [catid , subcategoryid]);



//  console.log(subcatList)



const handelProduct=(e)=>{
  setFilter(e.target.value)
  if(e.target.value=="All")
  {
    
    getProductsByCategoryId()
    
  }else if(e.target.value=="maxprice")
  {
    let ar=[]
    ar=totaldataList.sort((a,b)=>b.pro_price - a.pro_price)
    setDataList(ar)
  }else if(e.target.value=="minprice")
  {
    let ar=[]
    ar=totaldataList.sort((a,b)=>a.pro_price - b.pro_price)
    setDataList(ar)
  }else{
    // let ar=[]
    // setDataList(ar)
  }
  
}



  useEffect(() => {
   setFilter("All")
  }, [catid , subcategoryid]);

  return (
    <>
    <Helmet>
                <meta charSet="utf-8" />
                <title>{query?.length > 0? `Buy ${query} items online at low price` : `Buy ${catslag} items online at low price`}</title>
                <meta name="description" content={query?.length > 0? `Buy ${query} items online at low price` : `Buy ${catslag} items online at low price`} />
            </Helmet>
      <div className="product">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-9 col-md-7 col-sm-5 col-3"></div>
            <div className="col-lg-3 col-md-5 col-sm-7 col-9">
              <div class="form-group">
                <select class="form-control filterform" value={filter} onChange={handelProduct}>
                  
                  <option value="All">All</option>
                  <option value="maxprice">Price:Top-Low</option>
                  <option value="minprice">Price:Low-Top</option>
                  <option value="maxrating">Rating:Top-Low</option>
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            {
              subcatList?.length > 0 ?<>
              {
                      subcatList?.map((val,ind)=>{
                        return(
                          <>
                          <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6 mb-3" key={ind}>
                          <div className="card p-3 sub_Cat_card">
                        <NavLink to={`/category/${removeSpecialCharacters(catslag).trim().toLowerCase().replace(/\s/g, "-")}/${catid}?query=${removeSpecialCharacters(val.subcat_name).trim().toLowerCase().replace(/\s/g, "-")}&sid=${val._id}`}>
                        <img src={`${path}/image/product.png`} alt="" className='cat_img' draggable="false" />
                        <div className="p-1 text-center">
                            <p className='m-0'>{val.subcat_name}</p>
                        </div>
                        </NavLink>

                    </div>
                    </div>
                          </>
                        )
                      })
                    }
              
              
              </>:<>
              
              
              
              </>
            }
          
                    
               
          </div>

          {
            query?.length > 0 ? <h4 className="my-3 text-custom ml-2">All {query.split("-").join(" ")} items</h4> : <h4 className="my-3 text-custom ml-2">All {catslag.split("-").join(" ")} items</h4>
          }

          
          <div className="product_flex">
            {dataList?.map((val, ind) => {
              return (
                <>
                  {preload ? (
                    <>
                      <SkeletonTheme
                        baseColor="#f2f2f2"
                        highlightColor="#fff"
                        key={ind}
                      >
                        <div className="product_item">
                          <div className="card  top_card_link">
                            <Skeleton height={330} />
                          </div>
                        </div>
                      </SkeletonTheme>
                    </>
                  ) : (
                    <>
                      <div className="product_item" key={ind}>
                        <div className="card p-3 top_card_link">
                          <NavLink
                            to={`/product/${removeSpecialCharacters(val.pro_title)
                              ?.trim()
                              .toLowerCase()
                              .replace(/\s/g, "-")}/${val._id}/${location}`}
                          >
                            {val.pro_images?.length && (
                              <img
                                src={val.pro_images[0]}
                                alt={val?.pro_metadetails?.img_alt.length > 0 ?val?.pro_metadetails?.img_alt : val?.pro_title }
                                className="cat_img"
                                draggable="false"
                              />
                            )}

                            <p className="top_p" title={val.pro_title}>{val.pro_title}</p>
                            <h5 className="m-0">
                              ₹
                              { Math.ceil (val.pro_price -
                                val.pro_price * (val.pro_discount / 100))}
                            </h5>
                            <p className="span_line">
                              Actual Price:
                              <span style={{ textDecoration: "line-through" }}>
                                {" "}
                                ₹{val.pro_price}
                              </span>
                            </p>
                          </NavLink>
                          <div className="text-center">
                            <button
                              className="btn btn-outline-danger ml-1 mr-1"
                              onClick={() => addtocart(val._id)}
                            >
                              <i class="bx bx-cart-add"></i>
                            </button>
                            <button className="btn btn-danger ml-1 mr-1" onClick={()=>addtowishlist(val._id)} >
                              <i class="bx bx-heart"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </>
              );
            })}
          </div>
        </div>
      </div>



      {
        preload && (
          <>
          <div className="pageloading">
          <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          </div>

          </>
        )
      }
    </>
  );
};

export default CategoryDet;
