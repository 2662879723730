import React from 'react'
import { useContext } from 'react';
import { NavLink , useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { DataContext } from '../../context/DataContext';
import axios from 'axios';
import Cookies from 'js-cookie';
import { apilink } from '../../data/fdata';

const WishListCard = ({dataList,setDataList,wish_product,wish_id}) => {
  const token = Cookies.get('_indiayour_client_access_token');
  const his = useHistory();
  const { addtocart , alert , removeSpecialCharacters , location } = useContext(DataContext);

  const removeFromWishlist=async(id)=>{
    const res=await axios.get(`${apilink}/api/wishlist/removeFromWishlist/${id}`,{
      headers: {
        Authorization: token,
      },
    })
    // console.log(res.data)

    if(res.data.success)
    {
      setDataList(dataList.filter((v)=>v._id != id))
      alert.success("Item removed successfully")

    }else{
      if(res.data.msg=="Invalid Authentication.")
        {
          
          Cookies.remove('_indiayour_client_access_token');
          localStorage.removeItem('_indiayour_client_access_login');
          console.clear();
          window.location.href = '/login';
         
        }else{
          alert.error("Something wrong")
          his.push("/something-wrong")
        }
    }
  }
  return (
    <>
     <div className="wish_item">
              <NavLink target="_blank" to={`/product/${removeSpecialCharacters(wish_product?.pro_title)?.trim().toLowerCase().replace(/\s/g, "-")}/${wish_product._id}/${location}`}>
                {
                  wish_product?.pro_images?.length &&   <img
                  src={wish_product?.pro_images[0]}
                  alt={wish_product?.pro_metadetails?.img_alt.length > 0 ? wish_product?.pro_metadetails?.img_alt : wish_product?.pro_title }
                  draggable="false"
                />
                }
             
              <p className="top_p" title={wish_product?.pro_title}>
              {wish_product?.pro_title}
              </p>
            </NavLink>
            <div className="text-center mt-3 mb-3">
              <button className='btn btn-primary' onClick={()=>addtocart(wish_product._id)}> <i className="fa fa-cart-plus"></i> &nbsp;Add To Cart</button>
            </div>
            <div className="cross_box" onClick={()=>removeFromWishlist(wish_id)}>
              &times;
            </div>
              </div>

    
    </>
  )
}

export default WishListCard