import React from 'react'
import './Account.css'
import { NavLink , useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import WishListCard from '../../component/cardcompo/WishListCard'
import Cookies from 'js-cookie'
import { apilink, path } from '../../data/fdata'
import { useEffect } from 'react'
import { useState } from 'react'
import axios from 'axios';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';



const WishList = () => {

  const token = Cookies.get('_indiayour_client_access_token');
  const his = useHistory();
  const [dataList, setDataList] = useState([]);  
  const [pageloading, setPageLoading] = useState(false);




  const getWistListByUserId=async()=>{
    const res=await axios.get(`${apilink}/api/wishlist/getWistListByUserId`,{
      headers: {
        Authorization: token,
      },
    })
    // console.log(res.data)
    if(res.data.success)
    {
      setDataList(res.data.result)

    }else{
      if(res.data.msg=="Invalid Authentication.")
        {
          
          Cookies.remove('_indiayour_client_access_token');
          localStorage.removeItem('_indiayour_client_access_login');
          console.clear();
          window.location.href = '/login';
         
        }else{
          alert.error("Something wrong")
          his.push("/something-wrong")
        }

    }
  }


  useEffect(async() => {
    if(token) {
      setPageLoading(true)
        const res=await axios.get(`${apilink}/api/user/authVerify`,{
            headers: {
              Authorization: token,
            },
          })
          if(!res.data.success)
          {
            Cookies.remove('_indiayour_client_access_token');
           localStorage.removeItem('_indiayour_client_access_login');
          console.clear();
          window.location.href = '/login';
          }
          setTimeout(() => {
            setPageLoading(false)
          }, 2000);
         
    }else{
      his.push("/login")
    }
  }, []);


 useEffect(()=>{
  getWistListByUserId()
 })



  return (
    <>
    <div className="account">
      <div className="container">
        <h3 className='mb-5'>My Wishlist</h3>
        
             {
              dataList.length > 0 ?
              <>
              <div className="wish-flex">
              {
              dataList?.map((val,ind)=>{
                return(
                  <>
                  {
                    pageloading ? <>
                  
                  <SkeletonTheme
                             baseColor="#f2f2f2"
                             highlightColor="#fff"
                            key={ind}
                          >
                            <Skeleton height={300} />
                            </SkeletonTheme>

                 
                    </> :<>

                    <WishListCard dataList={dataList} setDataList={setDataList} wish_product={val?.wish_product} wish_id={val._id}  />

                    </>
                  }
                  
                  
                  </>
                )
              })
             }

</div>
              
              
              </>:<>
              
              <div>
                <img src={`${path}/image/noitem.webp`} alt="" className='noitem_img' />
                <h5 className='text-center p-2'>No Item Found</h5>
              </div>
              
              </>
             }


              
        
      </div>
    </div>

    {
        pageloading && (
          <>
          <div className="pageloading">
          <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          </div>

          </>
        )
      }
    </>
  )
}

export default WishList