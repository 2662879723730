




import React from 'react'
import './Product.css'
import { NavLink } from 'react-router-dom'
import { useEffect } from 'react'
import axios from 'axios'
import { useState } from 'react'
import { apilink } from '../../data/fdata'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useContext } from 'react'
import { DataContext } from '../../context/DataContext'


const AllProducts = () => {
  const [dataList,setDataList]=useState([])
  const [totaldataList,setTotalDataList]=useState([])
  


  const [preload,setPreload]=useState(false)
  const { addtocart , addtowishlist , removeSpecialCharacters , location} = useContext(DataContext);

  const [filter, setFilter] = useState("All");


  const getAllProducts=async()=>{
    setPreload(true)
    const res=await axios.get(`${apilink}/api/product/getAllProducts`)
    console.log(res.data)
    if(res.data.success){
      setDataList(res.data.result)
      setTotalDataList(res.data.result)
     

    }
    setTimeout(() => {
      setPreload(false)
    }, 3000);
  }

  useEffect(()=>{

    getAllProducts()

  },[])

  const handelProduct=(e)=>{
    setFilter(e.target.value)
    if(e.target.value=="All")
    {
      
     getAllProducts()
      
    }else if(e.target.value=="maxprice")
    {
      let ar=[]
      ar=totaldataList.sort((a,b)=>b.pro_price - a.pro_price)
      setDataList(ar)
    }else if(e.target.value=="minprice")
    {
      let ar=[]
      ar=totaldataList.sort((a,b)=>a.pro_price - b.pro_price)
      setDataList(ar)
    }else{
      // let ar=[]
      // setDataList(ar)
    }
    
  }
  return (
    <>
     <div className="product">
     
        <div className="container-fluid">
       <div className="row">
       <div className="col-lg-9 col-md-7 col-sm-5 col-3">
        </div>
       <div className="col-lg-3 col-md-5 col-sm-7 col-9">
       <div class="form-group">
       <select class="form-control filterform" value={filter} onChange={handelProduct}>
                  
                  <option value="All">All</option>
                  <option value="maxprice">Price:Top-Low</option>
                  <option value="minprice">Price:Low-Top</option>
                  <option value="maxrating">Rating:Top-Low</option>
                </select>
            </div>
       </div>
       </div>
        <div className="product_flex">
               {
                dataList?.map((val,ind)=>{
                    return(
                        <>


            {preload ? (
                        <>
                          <SkeletonTheme
                            baseColor="#f2f2f2"
                            highlightColor="#fff"
                            key={ind}
                          >
                            <div className="product_item">
                     <div  className="card  top_card_link">
                     <Skeleton height={320} />
                      </div>
                     </div>
                              
                            
                          </SkeletonTheme>
                        </>
                      ):(<>
           <div className="product_item" key={ind}>
                     <div  className="card p-3 top_card_link">
                            <NavLink to={`/product/${removeSpecialCharacters(val.pro_title)?.trim().toLowerCase().replace(/\s/g, "-")}/${val._id}/${location}`}>
                              {
                                 val.pro_images?.length && <img src={val.pro_images[0]} alt={val?.pro_metadetails?.img_alt.length > 0 ?val?.pro_metadetails?.img_alt : val?.pro_title } className='cat_img' />
                              }
                            
                            <p className='top_p' title={val.pro_title}>{val.pro_title}</p>
                            <h5 className='m-0'>₹ { Math.ceil( val.pro_price - (val.pro_price*(val.pro_discount/100)))}</h5>
    <p className='span_line'>Actual Price:<span style={{textDecoration:"line-through"}}> ₹{val.pro_price}</span></p>
                            </NavLink>
                           <div className="text-center">
                           <button className='btn btn-outline-danger ml-1 mr-1' onClick={()=>addtocart(val._id)}><i class='bx bx-cart-add'></i></button>
                            <button className='btn btn-danger ml-1 mr-1' onClick={()=>addtowishlist(val._id)}><i class='bx bx-heart'></i></button>
                           </div>

                           </div>
                    </div>

                      </>

                    )}



                         
                        
                        </>
                    )
                })
               }
               
            </div>
            </div>
        </div>
        {
        preload && (
          <>
          <div className="pageloading">
          <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          </div>

          </>
        )
      }
    
    </>
  )
}

export default AllProducts