import React from "react";
import { apilink, path } from "../../data/fdata";

import { useParams, NavLink, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useAlert } from "react-alert";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { CircularProgress } from "@mui/material";

const ActiveAccount = () => {
  const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(false);

  const alert = useAlert();

  const { activetoken } = useParams();
  // const urltoken = useLocation().search;
  
  // const activetoken= new URLSearchParams(urltoken).get('token');

  const accountActivate = async () => {
    setLoading(true);
    const res = await axios.post(`${apilink}/api/user/activation`, {
      activation_token:activetoken,
    });
    // console.log(res.data)
    if (res.data.success) {

      setTimeout(() => {
        alert.success(res.data.msg);
        setLoading(false);
      }, 2000);
    } else {
      

      setTimeout(() => {
        setStatus(true);
      alert.error(res.data.msg);
        setLoading(false);
      }, 2000);
    }
  };

  useEffect(() => {
    if (activetoken) {
      accountActivate();
    }
  }, [activetoken]);
  return (
    <>
      <div className="authbox">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-8 col-12 mx-auto">
              {
                loading?(
                  <>
                  <div className="card p-3">
                   <div className="text-center p-2">
                      <CircularProgress color="error"  size={40} />
                      <p>Loading...</p>
                    </div>
                   </div>
                  
                  </>
                ):(
                  <>
                  
                  
                  <div className="card p-3">
                {status ? (
                  <h4 className="text-center">Something Wrong</h4>
                ) : (
                  <>
                    <img
                      src={`${path}/image/success.gif`}
                      draggable="false"
                      alt=""
                      className="success_img"
                    />

                    <p className="text-success text-center m-0 mt-3">
                      Thank You!! Your Account has been activated
                    </p>
                    <br />
                    <div className="text-center">
                      <NavLink
                        className="btn btn-primary text-white"
                        to="/login"
                      >
                        Login Now
                      </NavLink>
                    </div>
                  </>
                )}
              </div>
                  </>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActiveAccount;
