import React from "react";
import "./Design.css";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useContext } from "react";
import { DataContext } from "../../context/DataContext";

const Bestitem = ({ text, list , preload }) => {
  // console.log(text)
  const { removeSpecialCharacters , location } = useContext(DataContext);

  return (
    <>
      <div className="design">
        <div className="dd_flex pt-3 pb-3">
          <h5>{text?.cat_name}</h5>
          <NavLink target="_blank" to={`/category/${removeSpecialCharacters(text?.cat_name)?.trim().toLowerCase().replace(/\s/g, "-")}/${text?._id}`} className="small_icon_div">
            <i className="fa fa-angle-right"></i>
          </NavLink>
        </div>
        <div className="design_flex">
          {
            list?.slice(0,4).map((val,ind)=>{
                return(
                    <>
                    {preload ? (
                        <>
                          <SkeletonTheme
                            baseColor="#f2f2f2"
                            highlightColor="#fff"
                            key={ind}
                          >
                           
                           <div className="design_item">
                                  <Skeleton height={250} />
                                
                              </div>
                           
                          </SkeletonTheme>
                        </>
                      ) : (
                        <>
<div className="design_item" key={ind}>
            <NavLink target="_blank" to={`/product/${removeSpecialCharacters(val.pro_title)?.trim().toLowerCase().replace(/\s/g, "-")}/${val._id}/${location}`}>
              <img
                src={val.pro_images.length && val.pro_images[0]}
                draggable="false"
                alt={val?.pro_metadetails?.img_alt.length > 0 ?val?.pro_metadetails?.img_alt : val?.pro_title }
              />
              <p className="top_p" title={val.pro_title}>
                {val.pro_title}
              </p>
            </NavLink>
          </div>

                        </>

                      )}
                    
                    
                    </>
                )
            })
          }
          
        </div>
      </div>
    </>
  );
};

export default Bestitem;
