import React, { useEffect, useState } from 'react';
import 'swiper/swiper.min.css';

import { Switch, Route, useHistory, useLocation } from 'react-router-dom';

import Error from './pages/Error';

import './App.css';

import Home from './pages/home/Home';

import { ConText } from './context/DataContext';
import Navbar from './component/navbar/Navbar';
import Footer from './component/footer/Footer';
import AllCategory from './pages/product/AllCategory';
import CategoryDet from './pages/product/CategoryDet';
import Register from './pages/auth/Register';
import Login from './pages/auth/Login';
import Account from './pages/account/Account';
import Orders from './pages/account/Orders';
import WishList from './pages/account/WishList';
import ProductDet from './pages/product/ProductDet';
import Cart from './pages/cart/Cart';
import Search from './pages/search/Search';
import AllProducts from './pages/product/AllProducts';
import ResetPassword from './pages/auth/ResetPassword';
import ForgetPassword from './pages/auth/ForgetPassword';
import ActiveAccount from './pages/auth/ActiveAccount';
import Wrong from './pages/Wrong';
import SubmitRequest from './pages/cart/SubmitRequest';
import OrderDetails from './pages/account/OrderDetails';
import RecentView from './pages/product/RecentView';
import PopUp from './component/popup/PopUp';
import { Helmet } from 'react-helmet';
import About from './pages/needpages/About';
import PrivacyPolicy from './pages/needpages/PrivacyPolicy';
import TermCondition from './pages/needpages/TermCondition';
import ContactUs from './pages/needpages/ContactUs';

const App = () => {
  const loc = useLocation();
  // console.log(loc)
  
  let patharray=["/login","/register","/error","/something-wrong"]

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    

  }, [loc]);
  return (
    <>
     
      <ConText>
      <Helmet>
                <meta charSet="utf-8" />
                <title>Shop Online for Industrial Tools, Safety Equipment, Power Tools & more </title>
                <meta
      name="description"
      content="Shop Online for Industrial Tools, Safety Equipment, Power Tools & more"
    />
            </Helmet>
        <Navbar />
        {
          !patharray.includes(loc.pathname) && <PopUp/>
        }
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/all-categories" component={AllCategory} />
          <Route exact path="/category/:catslag/:catid" component={CategoryDet} />        
          <Route exact path="/account" component={Account} />
          <Route exact path="/wishlist" component={WishList} />
          <Route exact path="/orders" component={Orders} />
          <Route exact path="/order/:oid" component={OrderDetails} />

          <Route exact path="/product/:slag/:proid/:place" component={ProductDet} />
          <Route exact path="/cart" component={Cart} />
          <Route exact path="/submit-request" component={SubmitRequest} />
          <Route exact path="/search" component={Search} />
          <Route exact path="/products" component={AllProducts} />
          <Route exact path="/recent-viewed" component={RecentView} />

          


          <Route exact path="/register" component={Register} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/reset-password/:accesstoken" component={ResetPassword} />
          <Route exact path="/forget-password" component={ForgetPassword} />
          <Route exact path="/verify-account/:activetoken" component={ActiveAccount} />

          
          <Route exact path="/contact" component={ContactUs} />
          <Route exact path="/aboutus" component={About} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/terms-conditions" component={TermCondition} />









          <Route exact path="/something-wrong" component={Wrong} />
          <Route component={Error} />
        </Switch>
        <Footer />
      </ConText>
    </>
  );
};

export default App;
