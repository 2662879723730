import React, { useEffect, useState } from 'react';
import './Footer.css';
import { NavLink } from 'react-router-dom';
import { useContext } from 'react';
import { DataContext } from '../../context/DataContext';
import { shuffleArray } from '../../data/fdata';

const Footer = () => {
  const { categoryList , removeSpecialCharacters , contactData } = useContext(DataContext);
 const [catArray,setCatArray]= useState([])
  useEffect(()=>{
setCatArray(shuffleArray(categoryList))
  },[categoryList])
// console.log(categoryList)

  return (
    <>
    <hr />
    <div className="container-fluid">
      <h5>Popular searches on LocalYour</h5>
      <div className="row mt-4">
        {
          categoryList?.map((val,ind)=>{
            return(
              <>
              
              <div className="col-md-6 col-12 mb-3 foot_p" key={ind}>
          <p className=''>{val.cat_name}:</p>
          <div>
            {
              val.sub_category?.map((v,i)=>{
                return(
                  <>
                  <NavLink target="_blank" key={i} to={`/category/${removeSpecialCharacters(val.cat_name).trim().toLowerCase().replace(/\s/g, "-")}/${val._id}?query=${removeSpecialCharacters(v.subcat_name).trim().toLowerCase().replace(/\s/g, "-")}&sid=${v._id}`} className="mx-1">{v.subcat_name} </NavLink> {val.sub_category.length != i+1 && "|"}
                  </>
                )
              })
            }
           
          </div>
        </div>
              </>
            )
          })
        }
       
      </div>
    </div>
      <footer>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-9 ">
<div className="row">
<div className="col-lg-3 col-md-3 col-sm-4 col-6 mb-3">
              <h5>Links</h5>
              <ul>
              <li><NavLink to="/">Home</NavLink></li>
                <li><NavLink to="/aboutus">About Us</NavLink></li>
                <li><NavLink to="/contact">Contact Us</NavLink></li>
                
                <li><NavLink to="/privacy-policy">Privacy Policy</NavLink></li>
                <li><NavLink to="/terms-conditions">Terms & Conditions</NavLink></li>




              </ul>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-4 col-6 mb-3">
              <h5>UserLinks</h5>
              <ul>
                <li><NavLink to="/account">Account</NavLink></li>
                <li><NavLink to="/orders">Orders</NavLink></li>
                <li><NavLink to="/wishlist">Whislists</NavLink></li>
                



              </ul>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-4 col-6 mb-3">
              <h5>Categories</h5>
              <ul>
                {
                  catArray?.slice(0,4)?.map((val,ind)=>{
                    return (
                      <li key={ind}><NavLink target="_blank" to={`/category/${removeSpecialCharacters(val.cat_name).trim().toLowerCase().replace(/\s/g, "-")}/${val._id}`}>{val.cat_name}</NavLink></li>
                    )
                  })
                }
               
               


              </ul>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-4 col-6 mb-3">
              <h5>Seller</h5>
             <p className='m-0 fn_14'>Want to sell your product on Indiayour? <a href="https://seller.indiayour.com" className='text-custom' target='_blank'>Register Now</a></p>
            </div>
</div>
            </div>
            <div className="col-lg-3  ">
           <ul>
            <li>Email: <a className='fn_16' href={`mailto:${contactData.email}`}>{contactData.email}</a></li>
            <li>Phone: <a className='fn_16' href={`tel:${contactData.phone}`}>{contactData.phone}</a></li>
            <li> 
            <p className='m-0'>{contactData.address}</p></li>
           </ul>
            <div className="social_links">
            <a href={contactData.facebook} target='_blank'><div>
                <i className='fa fa-facebook'></i>
              </div>
              </a>
              <a href={contactData.linkedin} target='_blank'><div>
                <i className='fa fa-linkedin'></i>
              </div>
              </a>
              <a href={contactData.instagram} target='_blank'><div>
                <i className='fa fa-instagram'></i>
              </div>
              </a>
              <a href={contactData.twitter} target='_blank'><div>
                <i className='fa fa-twitter'></i>
              </div>
              </a>
            </div>
            </div>


          </div>
        </div>
      </footer>
      
      <div className=" bg-pink py-4 px-2">
        <p className='text-center m-0'>Copyright © {new Date().getFullYear()} Indiayour. All Rights Reserved.</p>
      </div>
    </>
  );
};

export default Footer;
