import React, { useEffect, useState } from 'react'
import './Need.css'
import axios from 'axios'
import { apilink } from '../../data/fdata'
import parse from "html-react-parser";
import { CircularProgress } from "@mui/material";

const About = () => {
  const [loading, setLoading] = useState(false);
const [pageCont,setPageCont]=useState({})
  const getPageContent=async()=>{
    setLoading(true)
    const res=await axios.get(`${apilink}/api/pagecontent/getPageContent/ABOUTUS`)
    // console.log(res.data)
    if(res.data.success)
    {
      setPageCont(res.data.result)

    }
    setTimeout(() => {
      setLoading(false)
    }, 1000);
  }
  useEffect(()=>{
    getPageContent()
  },[])
  return (
    <>
    
    <div className="need">
        <div className="container">
            
            {loading ? (
                    <div className="text-center p-2 mt-5">
                      <CircularProgress color="error" size={35} />
                     <p> Loading...</p>
                    </div>
                  ):(
                    <>
                    <h1 className='text-custom mb-4'><u>About Us</u></h1>
                    {pageCont?.content && parse(pageCont.content) }
                    </>
                  )}

        
        </div>
    </div>
    </>
  )
}

export default About