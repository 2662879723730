import Cookies from "js-cookie";
import React from "react";
import { useState } from "react";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { apilink } from "../../data/fdata";
import axios from "axios";
import { useEffect } from "react";
import { useContext } from "react";
import { DataContext } from "../../context/DataContext";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { saveAs } from 'file-saver';

const OrderDetails = () => {
  const token = Cookies.get("_indiayour_client_access_token");
  const his = useHistory();
  const [pageloading, setPageLoading] = useState(false);
  const [orderDet, setOrderDet] = useState({});
  // const { gstPriceFunc } = useContext(DataContext);
  const { removeSpecialCharacters , location , gstPriceFunc , alert} = useContext(DataContext);


  let { oid } = useParams();

  const getOrdersByOrderId = async () => {
    const res = await axios.get(
      `${apilink}/api/order/getOrdersByOrderId/${oid}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    // console.log(res.data);
    if (res.data.success) {
      setOrderDet(res.data.result);
    } else {
      his.push("/something-wrong")
    }
  };

  useEffect(async () => {
    if (token) {
      setPageLoading(true);
      const res = await axios.get(`${apilink}/api/user/authVerify`, {
        headers: {
          Authorization: token,
        },
      });
      if (!res.data.success) {
        Cookies.remove("_indiayour_client_access_token");
        localStorage.removeItem("_indiayour_client_access_login");
        console.clear();
        window.location.href = "/login";
      } else {
        getOrdersByOrderId();
      }
      setTimeout(() => {
        setPageLoading(false);
      }, 2000);
    } else {
      his.push("/login");
    }
  }, []);


  const downloadpdf=async(id)=>{
    setPageLoading(true)

    const res=await axios.post(`${apilink}/api/invoice/invoice-pdf`,{
      invoice:id
    })
    // console.log(res.data)
   if(res.data.success)
   {
    alert.success(res.data.msg)
    const ress=await axios.get(`${apilink}/api/invoice/fetch-pdf/${id}`,{ responseType: 'blob' })
        if(ress)
        {
            const pdfBlob = new Blob([ress.data], { type: 'application/pdf' });

              saveAs(pdfBlob, `invoice_${id}.pdf`);
              const re=await axios.get(`${apilink}/api/invoice/delete-pdf/${id}`)
              if(!re.data.success)
              {
                his.push("something-wrong")
              }
        }

   }else{
    alert.error(res.data.msg)
   }
   setPageLoading(false)
  }

  return (
    <>
      <div className="account">
        <div className="container">
         {
          orderDet?._id ?(
            <>
             <div className="row">
            <div className="col-lg-12 col-md-12 col-12 mx-auto">
              <div className="row">
                <div className="col-lg-8 col-md-7 col-12 mx-auto mb-3">
                  <div className="card">
                  <div class="card-header dd_flex">
                <p className='m-0 '><span className='dblock'>#{orderDet?._id?.slice(0,7)}...</span> <span className='dview'>#{orderDet?._id}</span> </p>
                <div className='d-flex'>
                <p className='m-0'>{new Date(orderDet.createdAt).toLocaleDateString('en-GB')} &nbsp; &nbsp; <span className="downbtn" onClick={()=>downloadpdf(orderDet?.order_invoice_id)}><i class="fa fa-download mr-2" aria-hidden="true"></i> Estimate</span></p>
               

                </div>

              </div>
                    <div className="ddd_flex p-3">
                      <NavLink
                      to={`/product/${removeSpecialCharacters(orderDet?.order_product?.pro_title)?.trim().toLowerCase().replace(/\s/g, "-")}/${orderDet?.order_product?._id}/${location}`}
                      target="_blank"
                      >
                        {orderDet?.order_product?.pro_images.length > 0 && (
                          <img
                            src={orderDet?.order_product?.pro_images[0]}
                            alt=""
                            draggable="false"
                            className="card_img"
                          />
                        )}
                      </NavLink>
                      <div className=" ml-2">
                        <NavLink
                          to={`/product/${removeSpecialCharacters(orderDet?.order_product?.pro_title)?.trim().toLowerCase().replace(/\s/g, "-")}/${orderDet?.order_product?._id}/${location}`}
                          target="_blank"
                        >
                          <h5 className="m-0">
                            {orderDet?.order_product?.pro_title}
                          </h5>
                          <p className="m-0">
                            ₹  {gstPriceFunc(orderDet.order_price , 0,orderDet.order_product?.pro_gst_percentage) } 
                          </p>
                        </NavLink>
                        <p>
                          Qty: {orderDet?.order_qty} &nbsp; &nbsp;{" "}
                          

{ orderDet?.orderStatus =="Requested" && <span className='badge badge-warning'>Requested</span> } { orderDet?.orderStatus =="Rejected" && <span className='badge badge-danger'>Rejected</span> }  { (orderDet?.orderStatus =="Confirmed" || orderDet?.orderStatus=="Shipped" || orderDet?.orderStatus=="Delivered" ) && <span className='badge badge-success'>{orderDet?.orderStatus}</span> }
                        
                        
                        
                        </p>
                      </div>
                    </div>
                    
                  </div>
                </div>
                <div className="col-lg-4 col-md-5 col-12 mx-auto mb-3">
                  <div className="card p-3">
                    <p className="m-0">
                      <b>Product Price: </b> &nbsp;{" "}
                      {orderDet?.order_product?.pro_price} X{" "}
                      {orderDet?.order_qty} ={" "}
                      {orderDet?.order_product?.pro_price * orderDet?.order_qty}{" "}
                    </p>
                    <p className="m-0">
                      <b>Discount: </b> &nbsp;{" "}
                      {orderDet?.order_product?.pro_discount + orderDet?.added_discount}%
                    </p>
                    <p className="m-0">
                      <b>Discount Price: </b> &nbsp; ₹ {orderDet?.order_price}
                    </p>
                    <p className="m-0">
                      <b>GST: </b> &nbsp; 
                      {orderDet?.order_product?.pro_gst_percentage}%
                    </p>
                    <p className="m-0">
                      <b>Total Amount: </b> &nbsp; ₹{" "}
                      { (gstPriceFunc(orderDet?.order_price,0,orderDet?.order_product?.pro_gst_percentage))
                        
                          }
                    </p>
                  </div>
                </div>
              </div>


              <div className="row">
                <div className="col-lg-10 col-md-12 col-12 mb-3">
                  <div className="card p-3">
                  <div className="ddd_flex">
                    <div className="m-1 mr-2">
                    <p class="m-0 fn_18"><b>BILL TO</b></p>
                        <p class="m-0 "><b>{orderDet?.order_customer}</b>
                            </p>
                           
                            <p class="m-0">{orderDet?.order_name}</p>
                            <p class="m-0">{orderDet?.billing_address?.b_address}</p>
                            <p class="m-0">{orderDet?.billing_address?.b_city} , {orderDet?.billing_address?.b_district} , {orderDet?.billing_address?.b_state}</p>
                            <p class="m-0 ">India</p>
                            <p class="m-0">{orderDet?.order_phone}</p>
                            <p class="m-0"> {orderDet?.order_email}</p>
                    </div>
                    <div className="m-1 ml-lg-5">
                    <p class="m-0 fn_18"><b>SHIP TO</b></p>
                    <p class="m-0 "><b>{orderDet?.order_customer}</b>
                            </p>
                           
                            <p class="m-0">{orderDet?.order_name}</p>
                            <p class="m-0">{orderDet?.shipping_address?.s_address}</p>
                            <p class="m-0">{orderDet?.shipping_address?.s_city} , {orderDet?.shipping_address?.s_district} , {orderDet?.shipping_address?.s_state}</p>
                            <p class="m-0 ">India</p>
                            <p class="m-0">{orderDet?.order_phone}</p>
                            <p class="m-0"> {orderDet?.order_email}</p>
                    </div>
                  </div>
                    
                  </div>
                </div>
               
              </div>
            </div>
          </div>
            </>
          ):(
            <>
             <div className="row">
            <div className="col-lg-12 col-md-12 col-12 mx-auto">
              <div className="row">
                <div className="col-lg-8 col-md-7 col-12 mx-auto mb-3">
                <SkeletonTheme
                                  baseColor="#f2f2f2"
                                  highlightColor="#fff"
                                  
                                >
                                  <Skeleton height={240} className="mb-3" />
                                </SkeletonTheme>
                </div>
                <div className="col-lg-4 col-md-5 col-12 mx-auto mb-3">
                <SkeletonTheme
                                  baseColor="#f2f2f2"
                                  highlightColor="#fff"
                                  
                                >
                                  <Skeleton height={240} className="mb-3" />
                                </SkeletonTheme>
                </div>
              </div>


              <div className="row">
                <div className="col-lg-10 col-md-12 col-12 mb-3">
                <SkeletonTheme
                                  baseColor="#f2f2f2"
                                  highlightColor="#fff"
                                 
                                >
                                  <Skeleton height={240} className="mb-3" />
                                </SkeletonTheme>
                </div>
               
              </div>
            </div>
          </div>
            
            </>
          )
         }
        </div>
      </div>

      {pageloading && (
        <>
          <div className="pageloading">
            <div class="lds-spinner">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default OrderDetails;
