import React from "react";
import "./Auth.css";
import { useState } from "react";
import { CircularProgress } from "@mui/material";
import { useHistory } from "react-router-dom";
import { apilink, path } from "../../data/fdata";
import axios from "axios";
import Cookies from 'js-cookie';

const ForgetPassword = () => {
  const token = Cookies.get('_indiayour_client_access_token');
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState(false);
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const his = useHistory();

  const handleSubmit = async (e) => {
    setLoading(true)
    e.preventDefault();
    const res=await axios.post(`${apilink}/api/user/forgetPassword`,{
      email
    })
    if(res.data.success) {

      setTimeout(() => {
        setStatus(true)
      setMsg(res.data.msg)
      setLoading(false)
      }, 2000);

    } else {
      setTimeout(() => {
        setStatus(true)
      setMsg(res.data.msg)
      setLoading(false)
      }, 2000);
    }
  };

  return (
    <>
      <div className="authbox">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-8 col-12 mx-auto">
              <div className="card p-3">
                <img
                  src={`${path}/image/Indiayour.png`}
                  alt=""
                  className="authbox_logo"
                />
                <h5 className="text-center">Forget Password</h5>
                <p className="m-0 text-center">
                  Enter Your email to get your Reset Password link
                </p>
                {status && (
                  <div className="alert alert-warning alert-dismissible m-2">
                    <button
                      type="button"
                      className="close fn_14"
                      data-dismiss="alert"
                    >
                      &times;
                    </button>
                    {msg}
                  </div>
                )}
                <form onSubmit={handleSubmit} className="mt-5">
                  <div className="form-group ">
                    <input
                      type="email"
                      placeholder="Enter Account Email"
                      className="form-control"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                  <span className="cur mb-3" onClick={() => his.push("/login")}>
                    Back To Login
                  </span>

                  <div className="text-center">
                    <button
                      type="submit"
                      className={
                        loading
                          ? "dis btn btn-primary pl-5 pr-5"
                          : "btn btn-primary pl-5 pr-5"
                      }
                      disabled={loading}
                    >
                      Submit
                    </button>
                  </div>
                  {loading && (
                    <div className="text-center p-2">
                      <CircularProgress color="error" size={35} />
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgetPassword;
