import React from 'react';
import './Slider.css';
import SwiperCore, { Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { NavLink } from 'react-router-dom';
import { path } from '../../data/fdata';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import TopRateCard from '../cardcompo/TopRateCard';
import { useContext } from 'react';
import { DataContext } from '../../context/DataContext';
const TopRate = ({ text, list,  preload , cat_id }) => {
  // console.log(list)
  // SwiperCore.use([Autoplay]);
  const {  removeSpecialCharacters } = useContext(DataContext);

  SwiperCore.use([Autoplay, Navigation]);
  return (
    <>
      <div className="top__rate">
        {list.length == 0 ? (
          <>
            <div className="text-center">
              <p>There is no items</p>
            </div>
          </>
        ) : (
          <>
            <div className="container-fluid">
              <div className="dd_flex">
                <h4 className='text-custom'>{text}</h4>
                {
                  cat_id?.length >0 ? <>
                  <NavLink to={`/category/${removeSpecialCharacters(text)?.trim().toLowerCase().replace(/\s/g, "-")}/${cat_id}`}>
                  <i class="bx bx-right-arrow-alt"></i>
                </NavLink>
                  </>:<>
                  <NavLink to={`/products`}>
                  <i class="bx bx-right-arrow-alt"></i>
                </NavLink>
                  
                  </>
                }

                
              </div>

            
              <Swiper
              navigation={true}
               modules={[Autoplay, Navigation]}
                grabCursor={true}
                spaceBetween={10}
                slidesPerView={'auto'}
                loop={true}
                  autoplay={{ delay: 5000,disableOnInteraction: false,
                    reverseDirection: true
                     }}
              >
                {list?.map((val, ind) => {
                  return (
                    <>
                      {preload ? (
                        <>
                          <SkeletonTheme
                            baseColor="#0f0f0f"
                            highlightColor="#444"
                            key={ind}
                          >
                            <SwiperSlide >
                              <div className="top_rate_nav_link">
                                <div className="top__slider">
                                  <Skeleton height={330} />
                                </div>
                              </div>
                            </SwiperSlide>
                          </SkeletonTheme>
                        </>
                      ) : (
                        <>
                          <SwiperSlide key={ind}>
                             <TopRateCard pro_id={val._id} pro_img={val.pro_images}  pro_title={val.pro_title} pro_price={val.pro_price} pro_discount={val.pro_discount} pro_metadetails={val.pro_metadetails}/>
                            
                           
                          </SwiperSlide>
                        </>
                      )}
                    </>
                  );
                })}
              </Swiper>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default TopRate;
