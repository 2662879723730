import React from 'react';
import './Auth.css';
import { NavLink, useHistory } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { useState } from 'react';

import axios from 'axios';
import { apilink, path } from '../../data/fdata';
import Cookies from 'js-cookie';
import { useAlert } from 'react-alert';
const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [status, setStatus] = useState(false);
  const [msg, setMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const [ptype, setPtype] = useState("password");

 const alert= useAlert()
  const his = useHistory();

  const onRegister = async (e) => {
    setLoading(true);
    e.preventDefault();
    const data = {
      email,
      password,
    };
    const res = await axios.post(`${apilink}/api/user/login`, data);
    if (res.data.success) {
      localStorage.setItem('_indiayour_client_access_login', true);

      Cookies.set('_indiayour_client_access_token', res.data.access_token, {
        expires: 1,
      });

      setEmail("");
      setPassword("")
      setMsg("Login Successfully Done")
      setStatus(true)

      setTimeout(() => {
        window.location.href = '/';
       
      }, 1000);

      
    } else {
      
      setStatus(true);
      setMsg(res.data.msg);
      
    }
    setLoading(false);

    
  };
  return (
    <>
      <div className="register">
        <div className="register_box">
          {/* <input type="checkbox" id="flip" /> */}
          <div className="cover">
            <div className="front">
            <img
                src={`${path}/image/handshake.jpg`}    draggable="false"            alt=""
              />
              <div className="text">
                <span className="text-1">
                  <span className="">Indiayour</span>
                </span>
                <span className="text-2">Indiayour | B2B Supplier</span>
              </div>
            </div>
          </div>
          <div className="forms">
            <div className="form-content">
              <div className="signup-form">
                {status && (
                  <div className="alert alert-warning alert-dismissible fn_14">
                    <button
                      type="button"
                      className="close fn_14"
                      data-dismiss="alert"
                      onClick={()=>setStatus(false)}
                    >
                      &times;
                    </button>
                    {msg}
                  </div>
                )}
                <div className="title">Login</div>
                <form onSubmit={onRegister}>
                  <div className="input-boxes">
                    <div className="input-box">
                      <i className="fa fa-envelope"></i>
                      <input
                        type="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                    <div className="input-box">
                      <i className="fa fa-lock"></i>
                      <input
                        type={ptype}
                        placeholder="Enter your password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                       {ptype == 'password' ?  <i className='fa fa-eye-slash eye_input' onClick={()=>setPtype("text")}></i> :  <i className='fa fa-eye eye_input' onClick={()=>setPtype("password")}></i> }
                    </div>
                    <div className="button input-box">
                      <input
                        type="submit"
                        value="Submit"
                        className={loading && 'dis'}
                        disabled={loading}
                      />
                    </div>
                    {loading && (
                      <div className="text-center p-2">
                        <CircularProgress color='error' size={35} />
                      </div>
                    )}
                    <div className="text sign-up-text">
                      Don't have an account?{' '}
                      <label>
                        <label>
                          <NavLink to="/register"> SignUp Now</NavLink>
                        </label>
                      </label>
                    </div>
                    <div className="text-center uptext">
                    <NavLink to="/forget-password" className="text-custom"><small className='m-0'>Forget Password</small></NavLink>
                    </div>
                    
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
