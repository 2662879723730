import React, { useState } from 'react';
import './Auth.css';
import { NavLink, useHistory } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import axios from 'axios';
import { apilink, path } from '../../data/fdata';
import { useEffect } from 'react';
import Cookies from 'js-cookie';

const Register = () => {
  const token = Cookies.get('_indiayour_client_access_token');

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [status, setStatus] = useState(false);
  const [msg, setMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const [ptype, setPtype] = useState("password");

  const his = useHistory();


  const onRegister = async (e) => {
    setLoading(true);
    e.preventDefault();
    const data = {
      name,
      email,
      password,
    };

    const res=await axios.post(`${apilink}/api/user/register`,data)
    

    if (res.data.success) {
      
      setTimeout(() => {
        setEmail("");
      setName("")
      setPassword("")
        setLoading(false);
        setStatus(true);
        setMsg(res.data.msg);
      }, 1500);
      setTimeout(() => {
        his.push("/login")
      }, 2500);
    } else {
      setTimeout(() => {
        setLoading(false);
        setStatus(true);
        setMsg(res.data.msg);
      }, 1500);
     
    }
   

    
  };



  useEffect(async() => {
    if(token) {
      
        const res=await axios.get(`${apilink}/api/user/authVerify`,{
            headers: {
              Authorization: token,
            },
          })
          // console.log(res.data)
          if(res.data.success)
          {
            his.push("/account")
          }
         
    }
  }, []);
  // console.log(token);

  return (
    <>
      <div className="register">
        <div className="register_box">
          {/* <input type="checkbox" id="flip" /> */}
          <div className="cover">
            <div className="front">
              <img
                src={`${path}/image/handshake.jpg`}  draggable="false"              alt=""
              />
              <div className="text">
                <span className="text-1">
                  <span className="">Indiayour</span>
                </span>
                <span className="text-2">Indiayour | B2B Supplier</span>
              </div>
            </div>
          </div>
          <div className="forms">
            <div className="form-content">
              <div className="signup-form">
                {status && (
                  <div className="alert alert-warning alert-dismissible fn_14">
                    <button
                      type="button"
                      className="close fn_14"
                      data-dismiss="alert"
                      onClick={()=>setStatus(false)}
                    >
                      &times;
                    </button>
                    {msg}
                  </div>
                )}
                <div className="title">Signup</div>
                <form onSubmit={onRegister}>
                  <div className="input-boxes">
                    <div className="input-box">
                      <i className="fa fa-user"></i>
                      <input
                        type="text"
                        placeholder="Enter your name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="input-box">
                      <i className="fa fa-envelope"></i>
                      <input
                        type="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                    <div className="input-box">
                      <i className="fa fa-lock"></i>
                      <input
                        type={ptype}
                        placeholder="Enter your password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                     {ptype == 'password' ?  <i className='fa fa-eye-slash eye_input' onClick={()=>setPtype("text")}></i> :  <i className='fa fa-eye eye_input' onClick={()=>setPtype("password")}></i> }
                    </div>
                    <div className="button input-box">
                      <input
                        type="submit"
                        value="Submit"
                        className={loading && 'dis'}
                        disabled={loading}
                      />
                    </div>
                    {loading && (
                      <div className="text-center p-2">
                        <CircularProgress color='error'  size={35} />
                      </div>
                    )}
                    <div className="text sign-up-text">
                      Already have an account?{' '}
                      <label>
                        <NavLink to="/login">Login Now</NavLink>
                      </label>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
