import React from 'react'
import Bestitem from './Bestitem'
import './Design.css'
import { NavLink } from 'react-router-dom'
import { useEffect } from 'react'
import axios from 'axios';
import { useHistory } from "react-router-dom"
import { CategoryList, apilink } from '../../data/fdata'
import { useState } from 'react'

const WholeDesign = ({adsList}) => {

    const [catList,setCatList]=useState([])
    const [preload,setPreload]=useState(false)


    const [catoneList,setCatoneList]=useState([])
    const [catonetext,setCatonetext]=useState({})

    const [cattwoList,setCattwoList]=useState([])
    const [cattwotext,setCattwotext]=useState({})

    const [catthreeList,setCatthreeList]=useState([])
    const [catthreetext,setCatthreetext]=useState({})

    const [catfourList,setCatfourList]=useState([])   
    const [catfourtext,setCatfourtext]=useState({})

    const his = useHistory();





    function getRandomStrings(array, count) {
        const shuffled = array.slice(); // Create a shallow copy of the array
        for (let i = shuffled.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]]; // Swap elements to shuffle
        }
        return shuffled.slice(0, count);
      }
      
      
    //   
      


    const getCategoryData=async(text,count)=>{
      setPreload(true)
        const res=await axios.post(`${apilink}/api/product/getProductByCategory`,{
          pro_category:text?.cat_name
        })
        // console.log(res.data)
        if(res.data.success)
        {
          if(count==1)
          {
            setCatoneList(res.data.result)
            setCatonetext(text)
          }else if(count==2)
          {
            setCattwoList(res.data.result)
            setCattwotext(text)
          }
          else if(count==3)
          {
            setCatthreeList(res.data.result)
            setCatthreetext(text)
          }
          else if(count==4)
          {
            setCatfourList(res.data.result)
            setCatfourtext(text)
          }
    
        }else{
         his.push("/something-wrong")
        }

        setTimeout(() => {
          setPreload(false)
        }, 3000);
      }

      const genrandomarray=(arr)=>{


        arr.shift()
        
        // console.log(arr)
        const randomStrings = getRandomStrings(arr, 4);
        // console.log(randomStrings)

        for(let i=0; i<randomStrings.length; i++)
        {
            getCategoryData(randomStrings[i],i+1)
        }
        
      }

    useEffect(async()=>{
      

      const res=await axios.get(`${apilink}/api/category/getallCategory`)
    if(res.data.success)
    {
      setCatList(res.data.result)

      genrandomarray(res.data.result)

      
    }
        
        
    
      },[])


  return (
    <>
    
    <div className="wholedesign">
    <div className="container-fluid">
    <div className="row">
        <div className="col-lg-4 col-md-6 col-12 mb-5">
            <Bestitem text={catonetext} list={catoneList} preload={preload} />
            </div>
            <div className="col-lg-8 col-md-6 col-12 mb-5 addsbox">
              {
                adsList.filter((v)=>v.adsType=="ONE").slice(0,1).map((val,ind)=>{
                  return(
                    <>
                     <div>
                <a href={val.adslink} target="_blank"> <img src={val.adsImg} alt="" /></a>
            </div>
                    </>
                  )
                })
   }
           
            </div>
        </div>
        <div className="row">
            <div className="col-lg-4 col-md-6 col-12 mb-5">
            <Bestitem text={cattwotext} list={cattwoList}  preload={preload} />
            </div>
            <div className="col-md-6 col-12 mb-5 adsbox">
            {
                adsList.filter((v)=>v.adsType=="ONE").slice(0,1).map((val,ind)=>{
                  return(
                    <>
                     <div>
                <a href={val.adslink} target="_blank"> <img src={val.adsImg} alt="" /></a>
            </div>
                    </>
                  )
                })
   }
            </div>
            <div className="col-lg-4 col-md-6 col-12 mb-5">
           <Bestitem text={catthreetext} list={catthreeList}  preload={preload} />
            </div>
            <div className="col-lg-4 col-md-6 col-12 mb-5">
           <Bestitem text={catfourtext} list={catfourList}   preload={preload} />
            </div>
        </div>
        
    </div>
    </div>
    </>
  )
}

export default WholeDesign