import React from "react";

import { NavLink, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import OrderCard from "../../component/cardcompo/OrderCard";
import { useEffect } from "react";
import { useState } from "react";
import Cookies from "js-cookie";
import { apilink, path } from "../../data/fdata";
import axios from "axios";
import { useContext } from "react";
import { DataContext } from "../../context/DataContext";

import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Orders = () => {
  const token = Cookies.get("_indiayour_client_access_token");
  const his = useHistory();
  const { alert } = useContext(DataContext);

  const [pageloading, setPageLoading] = useState(false);
  const [pageloadingone, setPageLoadingOne] = useState(false);

  const [orderList, setOrderList] = useState([]);
  const [orderListTotal, setOrderListTotal] = useState([]);

  const [filter, setFilter] = useState("All");


  const getOrdersByUserId = async () => {
    const res = await axios.get(`${apilink}/api/order/getOrdersByUserId`, {
      headers: {
        Authorization: token,
      },
    });

    // console.log(res.data);

    if (res.data.success) {
      setOrderList(res.data.result);
      setOrderListTotal(res.data.result)
    } else {
      if (res.data.msg == "Invalid Authentication.") {
        setTimeout(() => {
          Cookies.remove("_indiayour_client_access_token");
          localStorage.removeItem("_indiayour_client_access_login");
          console.clear();
          window.location.href = "/login";
        }, 3000);
      } else {
        alert.error("Something wrong");

        setTimeout(() => {
          his.push("/something-wrong");
        }, 3000);
      }
    }
  };

  useEffect(async () => {
    if (token) {
      setPageLoading(true);
      const res = await axios.get(`${apilink}/api/user/authVerify`, {
        headers: {
          Authorization: token,
        },
      });
      if (!res.data.success) {
        Cookies.remove("_indiayour_client_access_token");
        localStorage.removeItem("_indiayour_client_access_login");
        console.clear();
        window.location.href = "/login";
      } else {
        getOrdersByUserId();
      }
      setTimeout(() => {
        setPageLoading(false);
      }, 2000);
    } else {
      his.push("/login");
    }
  }, []);

const handelOrder=(e)=>{
  setFilter(e.target.value)
  if(e.target.value=="All")
  {
    setOrderList(orderListTotal)
  }else{
    setOrderList(orderListTotal.filter((v)=>v.orderStatus == e.target.value))
  }
  
}

  return (
    <>
      <div className="account">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 col-12 mx-auto">
             {
              orderListTotal.length > 0 &&  <div className="row">
              <div className="col-lg-9 col-md-9 col-sm-8 col-7"></div>
              <div className="col-lg-3 col-md-3 col-sm-4 col-5">
                <div class="form-group">
                  <select class="form-control filterform" value={filter} onChange={handelOrder} >
                    
                    <option value="All">All</option>
                    <option value="Confirmed">Confirmed</option>
                    <option value="Rejected">Rejected</option>
                    <option value="Requested">Requested</option>
                    <option value="Shipped">Shipped</option>
                    <option value="Delivered">Delivered</option>
                  </select>
                </div>
              </div>
            </div>
             }
            </div>
          </div>

          <div className="row">
            <div className="col-lg-10 col-12 mx-auto">
              <div className="row">
                <div className="col-12">
                  {orderList.length > 0 ? (
                    <>
                      {orderList.map((val, ind) => {
                        return (
                          <>
                            {pageloading ? (
                              <>
                                <SkeletonTheme
                                  baseColor="#f2f2f2"
                                  highlightColor="#fff"
                                  key={ind}
                                >
                                  <Skeleton height={240} className="mb-3" />
                                </SkeletonTheme>
                              </>
                            ) : (
                              <>
                                <OrderCard
                                  key={ind}
                                  order_product={val.order_product}
                                  order_qty={val.order_qty}
                                  oid={val._id}
                                  odate={val.createdAt  }
                                  oprice={val.order_price}
                                  orderStatus={val.orderStatus}
                                  order_invoice_id={val.order_invoice_id}
                                  alert={alert}
                                  setPageLoadingOne={setPageLoadingOne}
                                  orderdet={val}

                                />
                              </>
                            )}
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      <div>
                        <img
                          src={`${path}/image/noitem.webp`}
                          alt=""
                          className="noitem_img"
                        />
                        <h5 className="text-center p-2">No Item Found</h5>
                        
                    
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {pageloading && (
        <>
          <div className="pageloading">
            <div class="lds-spinner">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </>
      )}
      {pageloadingone && (
        <>
          <div className="pageloading">
            <div class="lds-spinner">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Orders;
