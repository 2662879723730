import React, { useEffect, useState } from 'react';
import './Home.css';
import BanarSlider from '../../component/slider/BanarSlider';
import TopRate from '../../component/slider/TopRate';
import Ads from '../../component/slider/Ads';
import WholeDesign from '../../component/design/WholeDesign';
import PopUp from '../../component/popup/PopUp';
import { apilink } from '../../data/fdata';
import axios from 'axios';
import { useHistory } from "react-router-dom"
import RecentViewCompo from '../../component/slider/RecentViewCompo';
import { useContext } from 'react';
import { DataContext } from '../../context/DataContext';
const Home = () => {
  const [dataList,setDataList]=useState([])
  const [catList,setCatList]=useState([])
  const [catText,setCatText]=useState({})
  const [recentList,setRecentList]=useState([])
  const [bannerList,setBannerList]=useState([])
  const [adsList,setAdsList]=useState([])



  const [preload,setPreload]=useState(false)
  const { recentView, alert } = useContext(DataContext);

  const his = useHistory();

  const getalldata =async()=>{
    const res=await axios.get(`${apilink}/api/product/getRandomProduct`)
    if(res.data.success)
    {
      setDataList(res.data.result)

    }else{

      his.push("/something-wrong")

    }
  }

  const getCategoryData=async()=>{
    setPreload(true)
    const res=await axios.post(`${apilink}/api/product/getProductByCategory`,{
      pro_category:"Small Business Machinary"
    })
    // console.log(res.data)
    if(res.data.success)
    {
      setCatList(res.data.result)
      const ress=await axios.get(`${apilink}/api/category/getallCategory`)
      const obj=ress.data.result.filter((v)=>v.cat_name=="Small Business Machinary")
      setCatText(...obj)

    }else{
     his.push("/something-wrong")
    }
    setTimeout(() => {
      setPreload(false)
    }, 3000);
  }
  const getAllBanner=async()=>{
    
    const res=await axios.get(`${apilink}/api/banner/getAllBanner`)
    // console.log(.data)ta)
    if(res.data.success)
    {
      setBannerList(res.data.result)

    }
  }
const getAllAds=async()=>{
  
  const res=await axios.get(`${apilink}/api/adds/getAllAds`)
  console.log(res.data)
  if(res.data.success)
  {
    setAdsList(res.data.result)

  }
}
  useEffect(()=>{
    
    getalldata()
    getCategoryData()
    getAllBanner()
    getAllAds()

  },[])


  const getRecentViews=async()=>{
    // let data = JSON.stringify({ ids: recentView });
    // console.log(data)
    const res = await axios.post(`${apilink}/api/product/getrecentView`, recentView );
    // console.log(res.data)

     
    if(res.data.success)
    {
      setRecentList(res.data.result) 
    }
    
    // const res = await axios.post(
    //   `${apilink}/api/product/getCartDetails`,
    //   data,
    //   {
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //   }
    // );
    // console.log(res.data)
    // if(res.data.success)
    // {
    //   setRecentList(res.data.result) 
    // }
  }

  useEffect(()=>{
    if(recentView.length >0){
      getRecentViews()
    }

  },[recentView])

  
 
  return (
    <>
    {/* <PopUp/> */}
      <div className="home">
        {bannerList.length >0 && <BanarSlider bannerList ={bannerList} /> }
        <div className="banartext">
        <div className="container">
          <h3>Discover the Ultimate B2B Marketplace for Seamless Buying and Renting of Machinery , Equipment and Raw Materials.</h3>
        </div>
        </div>


       {dataList.length > 0 && <TopRate list={dataList} text="Best Sellers"  cat_id=""  preload={preload} /> } 
       {adsList.length > 0 && <Ads adsList={adsList} /> } 
       
      {catList.length > 0 &&  <TopRate list={catList} text={catText?.cat_name}  cat_id={catText?._id}  preload={preload} /> }  
        
        {adsList.length > 0 && <WholeDesign adsList={adsList} /> } 
        {
          recentList.length > 0 && <RecentViewCompo list={recentList} preload={preload} />
        }
          
        
      </div>

      {
        preload && (
          <>
          <div className="pageloading">
          <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          </div>

          </>
        )
      }
      
    </>
  );
};

export default Home;
