import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import './Slider.css';
import SwiperCore, { Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/components/navigation/navigation.min.css';
import { banarlist, path } from '../../data/fdata';
import { DataContext } from '../../context/DataContext';
const BanarSlider = ({bannerList}) => {
    SwiperCore.use([Autoplay, Navigation]);
    const {  removeSpecialCharacters } = useContext(DataContext);
  return (
    <>
    <div className="banar">
        <Swiper
          navigation={true}
          modules={[Autoplay, Navigation]}
          grabCursor={true}
          spaceBetween={5}
          slidesPerView={'auto'}
          loop={true}
          autoplay={{ delay: 3000, disableOnInteraction: false, reverseDirection: true
            }}
        >
          {bannerList?.map((v, ind) => (
            <SwiperSlide>
              <a href={v.bannerCatID} target="_blank" className="banar_link">
                <div className="banar__slider">
                
                <img src={v.bannerImg} alt="" draggable="false" />
                  
                 
                </div>
              </a>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  )
}

export default BanarSlider