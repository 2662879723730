import React from 'react';
import './Slider.css';
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { NavLink } from 'react-router-dom';
import { path } from '../../data/fdata';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import TopRateCard from '../cardcompo/TopRateCard';
import { useContext } from 'react';
import { DataContext } from '../../context/DataContext';

const RecentViewCompo = ({ list,  preload  }) => {
  // console.log(list)
 
  const { addtocart , addtowishlist, removeSpecialCharacters , location } = useContext(DataContext);

  SwiperCore.use([Autoplay]);



  

  return (
    <>
      <div className="top__rate">
        {list.length == 0 ? (
          <>
            
          </>
        ) : (
          <>
            <div className="container-fluid">
              <div className="dd_flex">
                <h4 className='text-custom'>Recent viewed</h4>
                
                  <NavLink to={`/recent-viewed`}>
                  <i class="bx bx-right-arrow-alt"></i>
                </NavLink>
                  
                  

                
              </div>
              {
                list.length > 6?(
                    <>
                    <Swiper
                modules={[Autoplay]}
                grabCursor={true}
                spaceBetween={10}
               
                slidesPerView={'auto'}
                loop={true}
                  autoplay={{ delay: 5000 ,disableOnInteraction: false, reverseDirection: true
                   }}
              >
                {list?.map((val, ind) => {
                  return (
                    <>
                      {preload ? (
                        <>
                          <SkeletonTheme
                            baseColor="#0f0f0f"
                            highlightColor="#444"
                            key={ind}
                          >
                            <SwiperSlide >
                              <div className="top_rate_nav_link">
                                <div className="top__slider">
                                  <Skeleton height={330} />
                                </div>
                              </div>
                            </SwiperSlide>
                          </SkeletonTheme>
                        </>
                      ) : (
                        <>
                         
                            {
                              val &&  <SwiperSlide key={ind}> <TopRateCard pro_id={val._id} pro_img={val.pro_images}  pro_title={val.pro_title} pro_price={val.pro_price} pro_discount={val.pro_discount} pro_metadetails={val.pro_metadetails}/>  </SwiperSlide>
                            }
                             
                            
                           
                         
                        </>
                      )}
                    </>
                  );
                })}
              </Swiper>
                    
                    
                    </>
                ):(
                    <>
                     <div className="product_flex">
                     {
                list?.map((val,ind)=>{
                    return(
                        <>


            {preload ? (
                        <>
                          <SkeletonTheme
                            baseColor="#f2f2f2"
                            highlightColor="#fff"
                            key={ind}
                          >
                            <div className="product_item">
                     <div  className="card  top_card_link">
                     <Skeleton height={250} />
                      </div>
                     </div>
                              
                            
                          </SkeletonTheme>
                        </>
                      ):(<>
           <div className="product_item" key={ind}>
                     <div  className="card p-3 top_card_link">
                            <NavLink to={`/product/${removeSpecialCharacters(val.pro_title)?.trim().toLowerCase().replace(/\s/g, "-")}/${val._id}/${location}`}>
                              {
                                 val.pro_images?.length && <img src={val.pro_images[0]} alt="" className='cat_img' />
                              }
                            
                            <p className='top_p' >{val.pro_title}</p>
                            <h5 className='m-0'>₹ {val.pro_price - (val.pro_price*(val.pro_discount/100))}</h5>
    <p className='span_line'>Actual Price:<span style={{textDecoration:"line-through"}}> ₹{val.pro_price}</span></p>
                            </NavLink>
                           <div className="text-center">
                           <button className='btn btn-outline-danger ml-1 mr-1' onClick={()=>addtocart(val._id)}><i class='bx bx-cart-add'></i></button>
                            <button className='btn btn-danger ml-1 mr-1' onClick={()=>addtowishlist(val._id)}><i class='bx bx-heart'></i></button>
                           </div>

                           </div>
                    </div>

                      </>

                    )}



                         
                        
                        </>
                    )
                })
               }
                     </div>
                    
                    
                    
                    </>
                )
              }

            
              
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default RecentViewCompo;
