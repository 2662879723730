import React, { useState } from "react";
import "./Product.css";
import TopRate from "../../component/slider/TopRate";
import { useEffect } from "react";
import { apilink, path } from "../../data/fdata";
import axios from "axios";
import { NavLink, useParams, useHistory, useLocation } from "react-router-dom";
import { useContext } from "react";
import { DataContext } from "../../context/DataContext";
import parse from "html-react-parser";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {Helmet} from "react-helmet";
import Tooltip from '@mui/material/Tooltip';
import {CopyToClipboard} from 'react-copy-to-clipboard';


const ProductDet = () => {
  const [imgLink, setImgLink] = useState("");
  const [product, setProduct] = useState({});
  const [preload, setPreload] = useState(false);
  const [openShareBox, setOpenShareBox] = useState(false);
  const [copied, setCopied] = useState(false);


  const [urlLink, setUrlLink] = useState("");

const loc=useLocation()
  
  const { addtocart, addtowishlist ,recentView, setRecentView ,location , discountPriceFunc , gstPriceFunc , contactData} = useContext(DataContext);


  const [similarProductList, setSimilarProductList] = useState([])

  let { slag, proid ,place } = useParams();
  const his = useHistory();



  const getSimilarProduct=async(id)=>{

    const res = await axios.get(
      `${apilink}/api/product/getProductsByCategoryId/${id}`
    );
    // console.log(res.data)
   
    if(res.data.success)
    {
     
      setSimilarProductList(res.data.result?.filter((v)=>v._id != proid))
    }else{
      his.push("/something-wrong");
    }

  }

  const getproductById = async () => {
    setPreload(true);
    const res = await axios.get(
      `${apilink}/api/product/getproductById/${proid}`
    );
    // console.log(res.data)
    if (res.data.success) {
      setProduct(res.data.result);
      setImgLink(res.data.result?.pro_images[0]);
      getSimilarProduct(res.data.result?.pro_category_id)
    } else {
      his.push("/something-wrong");
    }

    setTimeout(() => {
      setPreload(false);
    }, 3000);
  };

  

  useEffect(() => {
    if (proid) {
      getproductById();
      setUrlLink(`${path}${loc.pathname}`)
       let index=recentView.indexOf(proid)
      //  console.log(index)
      if(index == -1) {
        setRecentView([proid,...recentView])
      }else{
        recentView.splice(index, 1);
        setRecentView([proid,...recentView])
        // console.log("k")

      }
      
    }
  }, [proid]);

  useEffect(()=>{
    if(place=="DEMO")
    {
      // console.log(location)
      location.length > 0 && window.location.replace(`${path}/product/${slag}/${proid}/${location}`)

    }

  },[place , location ])

  const getRating = (arr) => {
    // console.log(arr)
    return (
      arr.reduce((accumulator, currentValue) => accumulator + currentValue, 0) /
      arr.length
    );
  };

  // console.log(similarProductList)
  

  return (
    <>
     <Helmet>
                <meta charSet="utf-8" />
                <title>{product?.pro_title && `Buy ${product?.pro_title} online at low price`}</title>
                <meta name="description" content={product?.pro_title && `Buy ${product?.pro_title} online at low price`} />
            </Helmet>

            {openShareBox && (
        <div className="model_box">
          <div className="inner_model">
            <div
              className="cross"
              onClick={() => {
                document
                  .querySelector("#root")
                  .classList.remove("disable-scroll");
                setOpenShareBox(false);
              }}
            >
              &times;
            </div>
            <div>
              {
                copied && <div class="alert alert-success alert-dismissible">
                <button onClick={()=>setCopied(false)} type="button" class="close" data-dismiss="alert">&times;</button>
                Url Successfully Copied!
              </div>
              }
              <div className="share_icon">
                  <a href={`https://www.facebook.com/sharer/sharer.php?u=${urlLink}}`}  target="_blank" className="share_icon_box">
                    <i className="fa fa-facebook text-primary"></i>
                  </a>
                  <a rel="noopener noreferrer" target="_blank" href={`https://web.whatsapp.com/send?text=${urlLink}`} className="share_icon_box">
                    <i className="fa fa-whatsapp text-success"></i>
                  </a>
                  <a href={`https://www.linkedin.com/sharing/share-offsite/?url=${urlLink}`} target="_blank" className="share_icon_box">
                    <i className="fa fa-linkedin text-primary"></i>
                  </a>
                  <a target="_blank" href={`https://twitter.com/intent/tweet?url=${urlLink}&text=Indiayour`} className="share_icon_box">
                    <i className="fa fa-twitter text-primary"></i>
                  </a>
              </div>
              <div className="form_flex">
                <input type="text" value={urlLink} readOnly />
                
                <CopyToClipboard text={urlLink} >
                
  <button onClick={()=>setCopied(true)} className="copy_btn"><i className="fa fa-clone"></i></button>   
</CopyToClipboard>
              
                
              </div>
            </div>
            
          </div>
        </div>
      )}
      <div className="product bg-light">
        <div className="container-fluid ">
          <div className="row ">
            <div className="col-lg-10 col-12 mx-auto ">
              {preload ? (
                <>
                  <div className="row">
                    <SkeletonTheme baseColor="#f2f2f2" highlightColor="#fff">
                      <div className="col-md-6 col-12 mb-3 det_card">
                      <Skeleton height={250} />

                        <div className="img_flex mt-2">
                          {product?.pro_images?.map((val) => {
                            return (
                              <>
                                <div className="img_flex_item">
                                <Skeleton height={70} width={80} />
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </div>

                      <div className="col-md-6 col-12 mb-3 det_card">
                      <Skeleton height={50}  />
                      <Skeleton height={50} width={150} />
                      <Skeleton height={30} width={250} />
                      <Skeleton height={30} width={250} />
                      <Skeleton height={45} width={200} />

                      <Skeleton height={70}   />
                      <Skeleton height={300}  />
                      </div>
                    </SkeletonTheme>
                  </div>
                </>
              ) : (
                <>
                  <div className="row">
                    <div className="col-md-6 col-12 mb-3 det_card">
                     <div className="position_sticky ">
                     <img src={imgLink} alt={product?.pro_metadetails?.img_alt.length > 0 ?product?.pro_metadetails?.img_alt : product?.pro_title } draggable="false" className="p_Det_img" />
                      <div
                        className="wish_box"
                        onClick={() => addtowishlist(product?._id)}
                      >
                        <i className="fa fa-heart"></i>
                      </div>
                      <div
                        className="share_box"
                        onClick={()=>{
                          document
                          .querySelector("#root")
                          .classList.add("disable-scroll");
                          setOpenShareBox(true)
                        }}
                      >
                        <i className="fa fa-share"></i>
                      </div>

                      <div className="img_flex mt-2">
                        {product?.pro_images?.map((val) => {
                          return (
                            <>
                              <div className="img_flex_item">
                                <img
                                  src={val}
                                  alt={product?.pro_metadetails?.img_alt.length > 0 ?product?.pro_metadetails?.img_alt : product?.pro_title }
                                  draggable="false"
                                  className="p_Det_sm_img"
                                  onClick={()=>setImgLink(val)}
                                />
                              </div>
                            </>
                          );
                        })}
                      </div>
                     </div>
                    </div>
                    <div className="col-md-6 col-12 mb-3 right_det_card">
                      <h3>{product?.pro_title}</h3>
                      {product?.pro_rating?.length > 0 && (
                        <>
                          <span class="badge badge-success p-1">
                            {getRating(product?.pro_rating)} &nbsp;{" "}
                            <i className="fa fa-star"></i>{" "}
                          </span>{" "}
                          ({product?.pro_rating?.length} Reviews)
                        </>
                      )}
                      {/* ({product?.pro_rating?.length} Reviews) */}
                      <p className="mt-1 mb-1">
                        <span className="pt_text">₹ {gstPriceFunc(product?.pro_price,product?.pro_discount,product?.pro_gst_percentage)}</span> (with GST)
                        {/* ₹
                        { Math.ceil(product?.pro_price -
                          product?.pro_price * (product?.pro_discount / 100)) } */}
                      </p>{" "}
                      <p className="mt-1 mb-1">
                        ₹ {discountPriceFunc(product?.pro_price,product?.pro_discount)} (without GST)
                       
                      </p>{" "}
                      <p className="mt-3">
                        <span className="discout_box">{product?.pro_discount}% OFF</span> &nbsp; &nbsp; <span style={{ textDecoration: "line-through" }}>
                        {" "}
                        ₹{product?.pro_price}
                      </span>
                      </p>
                      <div className="text-left mt-3 mb-2">
                        <button
                          className="btn btn-primary p-2 pl-4 pr-4"
                          onClick={() => addtocart(product?._id)}
                        >
                          <i className="fa fa-cart-plus"></i> &nbsp; Add To Cart
                        </button>
                        &nbsp; &nbsp;
                        <button
                          className="btn btn-primary p-2 pl-4 pr-4"
                          onClick={() =>{
                            addtocart(product?._id)
                            his.push("/cart")
                          }}
                        >
                          <i className="fa fa-shopping-bag "></i> &nbsp; Buy Now
                        </button>
                      </div>
                      <div className="pro_det_sum">
                        <h5>About This Product</h5>
                        {product?.pro_description &&
                          parse(product?.pro_description)}

                          
                      </div>
                      <br />
                      {
                        product?.pro_pdf_link?.length && <a target='_blank'  href={`https://docs.google.com/viewer?url=${product?.pro_pdf_link}&embedded=true&a=bi`}  className='bg-red  rounded p-2 text-light'>Product Details Pdf</a>
                      }

                      <div className="mt-4">
                        <p className="m-0 fn_14 text-custom"> <u>{product?.pro_title} is available in <b>{location}</b></u></p>
                        <p className="mt-3 fn_14">
                          <span className="text-custom ">Sell <span className="">{product?.pro_title}</span> in Indiayour : <a href="https://seller.indiayour.com" className="tttext" target="_blank">Register Now</a>.</span>
                          </p>
                        {/* <div>
                        
                        {
                          product?.pro_place?.map((va,index)=>{
                            return(
                              <span className="badge badge-info m-1" key={index}>{va}</span>
                            )
                          })
                        }
                        </div> */}
                      </div>
                    </div>
                  
                   <div className="col-12 mt-4">

<p className="fn_16 text-center  "><span className="p-2 rounded  bg-red">
Need help? Call at <a className=" text-light" href={`tel:${contactData.phone}`} >
    { contactData.phone}
    
  </a>
</span>
</p>
</div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <TopRate
        list={similarProductList}
        // text="Similar Products"
        text={product?.pro_category}  cat_id={product?.pro_category_id}  preload={preload}
      />
      {/* <TopRate
        list={[1, 2, 3, 4, 5, 6, 4, 7, 8, 8, 7]}
        text="Recently Viewed"
      /> */}

      {preload && (
        <>
          <div className="pageloading">
            <div class="lds-spinner">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ProductDet;
