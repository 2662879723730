import React, { useState } from 'react'
import { NavLink , useHistory } from 'react-router-dom'
import axios from 'axios'
import { apilink } from '../../data/fdata'
import { saveAs } from 'file-saver';
import { useContext } from 'react';
import { DataContext } from '../../context/DataContext';



const OrderCard = ({order_product,order_qty , oid,odate , oprice , orderStatus , order_invoice_id , alert , setPageLoadingOne , orderdet}) => {
   const [openpop,setOpenPop]= useState(false)
  const his = useHistory();
  const { removeSpecialCharacters , location , gstPriceFunc  } = useContext(DataContext);

   
    const onRating=async(e)=>{
        e.preventDefault()
      }

      const downloadpdf=async(id)=>{
        setPageLoadingOne(true)

        const res=await axios.post(`${apilink}/api/invoice/invoice-pdf`,{
          invoice:id
        })
        // console.log(res.data)
       if(res.data.success)
       {
        alert.success(res.data.msg)
        const ress=await axios.get(`${apilink}/api/invoice/fetch-pdf/${id}`,{ responseType: 'blob' })
            if(ress)
            {
                const pdfBlob = new Blob([ress.data], { type: 'application/pdf' });

                  saveAs(pdfBlob, `invoice_${id}.pdf`);
                  const re=await axios.get(`${apilink}/api/invoice/delete-pdf/${id}`)
                  if(!re.data.success)
                  {
                    his.push("something-wrong")
                  }
            }

       }else{
        alert.error(res.data.msg)
       }
       setPageLoadingOne(false)
      }
  return (
    <>
    
    <div className="card mb-3 ">
              <div class="card-header dd_flex">
                <p className='m-0 '><span className='dblock'>#{oid.slice(0,7)}...</span> <span className='dview'>#{oid}</span> </p>
                <div className='d-flex'>
                <p className='m-0'>{new Date(odate).toLocaleDateString('en-GB')}</p>
                <p className='m-0 ml-4 cur' onClick={()=>downloadpdf(order_invoice_id)}><i class="fa fa-download mr-2" aria-hidden="true"></i>
 Estimate</p>
                </div>

              </div>
              <div class="card-body">

            <div className="ddd_flex">
           <NavLink target="_blank" to={`/product/${removeSpecialCharacters(order_product?.pro_title)?.trim().toLowerCase().replace(/\s/g, "-")}/${order_product?._id}/${location}`}> 
           {
                        order_product?.pro_images.length > 0 && <img
                        src={order_product?.pro_images[0]}
                        alt={order_product?.pro_metadetails?.img_alt.length > 0 ?order_product?.pro_metadetails?.img_alt : order_product?.pro_title }
                        draggable="false" className='card_img' 
                      />
           }
           </NavLink>
           <div className=" ml-2">
            <NavLink target="_blank" to={`/product/${removeSpecialCharacters(order_product?.pro_title)?.trim().toLowerCase().replace(/\s/g, "-")}/${order_product?._id}/${location}`}><h5 className='m-0'>{order_product?.pro_title}</h5>
            <p className='m-0'>₹ {gstPriceFunc(oprice ,0, order_product?.pro_gst_percentage)}</p></NavLink>
            <p>Qty: {order_qty} &nbsp; &nbsp;  { orderStatus =="Requested" && <span className='badge badge-warning'>Requested</span> } { orderStatus =="Rejected" && <span className='badge badge-danger'>Rejected</span> }  { (orderStatus =="Confirmed" || orderStatus=="Shipped" || orderStatus=="Delivered" ) && <span className='badge badge-success'>{orderStatus}</span> } </p> 

          <div className="mt-2">
          {/* <NavLink to="/" className="btn btn-primary m-1">View Item</NavLink> */}
          {/* <button className="btn btn-light m-1" onClick={()=>{
            document.querySelector('#root').classList.add('disable-scroll');
            setOpenPop(true)}}>Write Review</button> */}
             <button className="btn btn-primary m-1" onClick={()=>his.push(`/order/${oid}`)} >View</button>

          </div>

           </div>
            </div>


              </div>

               
              </div>
              {
                openpop && <div className="model_box">
                <div className="inner_model p-3 pt-5">
                  <div className="cross"  onClick={()=>{
                    document.querySelector('#root').classList.remove('disable-scroll');
                    setOpenPop(false)}}>
                    &times;
                  </div>
                  <form onSubmit={onRating}>
                  <div class="form-group">
              <select class="form-control filterform" id="sel1">
                <option selected hidden value="">
                  --Give Your Rating--
                </option>
                <option value="2">2</option>
                <option value="2.5">2.5</option>
                  <option value="3">3</option>
                  <option value="3.5">3.5</option>
                  <option value="4">4</option>
                  <option value="4.5">4.5</option>
                  <option value="5">5</option>
              </select>
            </div>
            <div className="text-center">
                <button className="btn btn-primary">Submit Rating</button>
              </div>
                  </form>
                </div>
              </div>
              }
    
    </>
  )
}

export default OrderCard